import React from "react";
import {
  Box,
  Button,
} from '@chakra-ui/react';

import MobileInvoice from "./MobileInvoice";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const DownloadButton = ({ onClick }) => {
    return (
      <Button onClick={onClick}>
        Download
      </Button>
    );
  };


const MobileMyBill = () => {
    const invoiceData = {
        invoiceNumber: '12345',
        companyInfo: 'Your Company Info',
        purchaseItems: [
          { name: 'Item 1', price: '$20', tax: '$2', total: '$22' },
          { name: 'Item 2', price: '$30', tax: '$3', total: '$33' },
        ],
        total: '$100',
        tax: '$10',
      };
    
      const downloadPDF = async () => {
        const invoiceContainer = document.getElementById('invoice');
    
        if (!invoiceContainer) {
          console.error("Element with ID 'invoice' not found.");
          return;
        }
    
        const canvas = await html2canvas(invoiceContainer, { scale: 2 }); // Adjust scale if needed
    
        const pdf = new jsPDF('p', 'mm', 'a4'); // 'p' stands for portrait orientation
        const imgData = canvas.toDataURL('image/png');
    
        // Set the width and height to match the A4 dimensions
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('invoice.pdf');
      };
  return (
    <>
          <Box w={["100%","90%","90%","90%"]} m="auto" bg="white" p={2}>
            <Box display="flex" justifyContent="end">
              <DownloadButton onClick={downloadPDF} />
            </Box>
            <div id="invoice"> {/* Add the ID here */}
              <MobileInvoice invoiceData={invoiceData} />
            </div>
            
          </Box>
    </>
  )
}

export default MobileMyBill