import { Image, Td, Tr } from "@chakra-ui/react"

const PendingAdds = ({ props, i }) => {
    return (
        <Tr >
            <Td>{i + 1}</Td>
            <Td display="flex" gap="10">
                <Image src={`https://img.youtube.com/vi/${props.vId}/maxresdefault.jpg`} w="100px"/>
                {props.title}
            </Td>
            <Td>{props.vId}</Td>
            <Td>{props.add_date}</Td>
        </Tr>
    )
}
export default PendingAdds