import { Button, FormLabel, Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, useDisclosure, useToast } from "@chakra-ui/react"
import axios from "axios"
import { useState } from "react"
import { IoMdAddCircleOutline } from 'react-icons/io'

const init = {
    bank_name: "",
    account_holder_name: "",
    account_number: "",
    confirm_account_number:"",
    ifsc_code:""
}
const AddBank = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [formData, setFormData] = useState(init)
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(false)
    const userDetails = JSON.parse(localStorage.getItem('userDetails'))
    const userToken= localStorage.getItem('userToken')
    // formData.userId=userDetails.id
    const handelFormData = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {

        e.preventDefault()
        setIsLoading(true)
        const res = await axios.post(`${process.env.REACT_APP_SERVER}/add-bank`, formData,{
            headers:{
                Authorization: userToken
            }
        })
        if (res.status == 202) {
            setIsLoading(false)
            onClose()
            toast({
                title: 'Enter Correract Account Number',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
            })
            
        } else if (res.status == 200) {
            setIsLoading(false)
            onClose()
            toast({
                title: 'Add Scheduled',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top',
            })
        }
        
    }
    return (
        <>
            <Button bg="teal.600" color="white" _hover={{ bg: "teal.800" }} onClick={onOpen}><Icon as={IoMdAddCircleOutline} boxSize={5} mr={3} />Add Bank</Button>

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Schedule New Advertisement</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form action="" id="scheduleAd" onSubmit={handleSubmit}>
                            <FormLabel>Select Bank</FormLabel>
                            <Select name="bank_name" onChange={(e) => handelFormData(e)} required>
                                <option value="">Select Bank</option>
                                <option value="SBI">State Bank of India (SBI)</option>
                                <option value="HDFC">HDFC Bank</option>
                                <option value="ICICI">ICICI Bank</option>
                                <option value="PNB">Punjab National Bank (PNB)</option>
                                <option value="Axis">Axis Bank</option>
                                <option value="BoB">Bank of Baroda (BoB)</option>
                                <option value="Canara">Canara Bank</option>
                                <option value="Union">Union Bank of India</option>
                                <option value="IDBI">IDBI Bank</option>
                                <option value="Kotak">Kotak Mahindra Bank</option>
                                <option value="IndusInd">IndusInd Bank</option>
                                <option value="Yes">Yes Bank</option>
                                <option value="Federal">Federal Bank</option>
                                <option value="Indian">Indian Bank</option>
                                <option value="BoI">Bank of India</option>
                                <option value="Central">Central Bank of India</option>
                                <option value="Corporation">Corporation Bank</option>
                                <option value="OBC">Oriental Bank of Commerce (OBC)</option>
                                <option value="Andhra">Andhra Bank</option>
                                <option value="SBM">State Bank of Mysore (SBM)</option>
                            </Select>
                            <FormLabel>Account Holder Name</FormLabel>
                            <Input name="account_holder_name" type="text" onChange={(e) => handelFormData(e)} required />
                            <FormLabel>Account Number</FormLabel>
                            <Input name="account_number" type="password" id="account_number" onChange={(e) => handelFormData(e)} required />
                            <FormLabel>Confirm Account Number</FormLabel>
                            <Input name="confirm_account_number" type="text" onChange={(e) => handelFormData(e)} required />
                            <FormLabel>IFSC Code</FormLabel>
                            <Input name="ifsc_code" type="text" onChange={(e) => handelFormData(e)} required />
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' type="submit" mr={3} isLoading={isLoading} loadingText={"Saveing..."} form="scheduleAd">
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AddBank