import React, { useEffect, useState } from "react";
import {
  Box,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import { MobileNav, SidebarContent } from "../../components/Dashboard/Sidebar";
import YouTube from "react-youtube";
import axios from "axios";


const MyActivity = () => {
  const [showAnimation, setShowAnimation] = useState(false);
  const [animationPosition, setAnimationPosition] = useState('-100px');
  const [isPlay, setIsPlayed] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userDetails = JSON.parse(localStorage.getItem('userDetails'))
  const [videoId, setVideoId] = useState([])

  const getAddsData = () => {
    axios.get(`${process.env.REACT_APP_SERVER}/get-adds`)
      .then((res) => {
        const filteredData = res.data.filter(item => item.is_active === true);
        setVideoId(filteredData[0] ? filteredData[0].vId : 0);
      })
  }
  useEffect(() => {
    getAddsData()
  }, [])
  // const img1 = `https://img.youtube.com/vi/${videoId}/0.jpg`

  const onVideoPlay = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER}/add-watch-history`, {
        userId: userDetails.id,
        video_id: videoId,
        is_viewed: true
      })
      .then((watchHistoryResponse) => {
        if (watchHistoryResponse.status === 200) {
          // If adding watch history was successful, add wallet points
          axios
            .post(`${process.env.REACT_APP_SERVER}/add-wallate-point`, {
              type: "watchPoints",
              amount: userDetails.userPackage.ads_point/30,
              transaction_type: "cr",
              userId: userDetails.id,
            })
            .then((walletPointResponse) => {
              if (walletPointResponse.status === 200) {
                // If adding wallet points was successful, play the video
                setShowAnimation(true);
                setAnimationPosition('0px');
                setIsPlayed(true);

                // Reset the animation after a delay (e.g., 2 seconds)
                setTimeout(() => {
                  setShowAnimation(false);
                  setAnimationPosition('-100px');
                }, 2000);
              }
            });
        }
      });
  };
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  return (
    <>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          {/* Content */}

          {showAnimation && (
            <div className="animation-text" style={{ top: animationPosition }}>
              { userDetails.userPackage.ads_point/30} +
            </div>
          )}
          <Box display="flex" justifyContent="center">
            {
              videoId ?
                <YouTube
                  videoId={videoId}
                  onPlay={onVideoPlay}
                />
                :
                <Box w="50%">
                <Image src="https://images.squarespace-cdn.com/content/v1/609b7a1cd921026628046b01/1625639360446-XVW9PVI8WZYQ1H6VAMU9/Donate.gif" w="50"/>
                <Text fontSize="25px" fontWeight="bold">No Add For Today</Text>
                </Box>
            }

          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MyActivity;