import React, { useState } from "react";
import {
  Box,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { AiOutlineEdit } from "react-icons/ai"
import { MdOutlineUnfoldMoreDouble } from "react-icons/md"
import { MobileNav, SidebarContent } from "../components/AdminSidebar"
import axios from "axios";
import AddPackages from "../components/Packages/AddPackages";

const Package = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [packages, setPackages] = useState([])

  const getPackages = async () => {
    await axios.get(`${process.env.REACT_APP_SERVER}/get-packages`)
      .then((res) => setPackages(res.data))
  }
  useState(() => {
    getPackages()
  }, [])

  const formatDate = (dateString) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          {/* Content */}
          Package
          <Box w="100%" display="flex" alignItems="end" justifyContent="end">
                        <AddPackages/>
                        
                    </Box>
          <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"]}>
            {
              packages.map((item) => (
                <GridItem borderRadius="lg"
                  overflow="hidden"
                  boxShadow="md"
                  className="bg-light shadow-sm"
                  p={4}>
                  <Box borderRadius="lg" overflow="hidden" boxShadow="md" p="4" className="bg-body">
                    <Box display="flex" justifyContent="space-between" borderBottom="1px solid white">
                      <Box>
                        <Text fontWeight="bold" fontSize="30px">{item.name}</Text>
                        <Text fontSize="16px" lineHeight="0.5">₹ {item.price}</Text>
                      </Box>
                      <Box>
                        <Icon as={AiOutlineEdit}></Icon>
                      </Box>
                    </Box>
                    <Box pt="10">
                      <Text fontSize="12px" lineHeight="0" pt="5px">Created At: {formatDate(item.createdAt)}</Text>
                      <Text fontSize="12px" lineHeight="0">Updated At: {formatDate(item.updatedAt)}</Text>
                      <Text fontSize="15px">Number Of Videos: {item.videos.length}</Text>
                    </Box>
                    <Box display="flex" justifyContent="end" pt="10">
                      <Icon as={MdOutlineUnfoldMoreDouble} />
                    </Box>
                  </Box>
                </GridItem>
              ))
            }
          </Grid>
          {/* <Box borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="md"
            bgGradient="linear(to-b, teal.200, teal.400)"
            p={4}
            _hover={{ bgGradient: "linear(to-b, teal.300, teal.500)" }}>
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" boxShadow="md" >
              {packages.map((cart, index) => (
                <Box key={index} p={4}>
                  <Box display="flex" justifyContent="space-between" borderBottom="1px solid white">
                    <Box>
                      <Box>
                        <Text fontWeight="bold" fontSize={["25", "30", "40", "40"]}>{cart.name}</Text>
                      </Box>
                      <Box >
                        <Text fontWeight="bold" fontSize={["15", "15", "20", "20"]} >₹{cart.price}</Text>
                      </Box>
                    </Box>

                    <Box>
                      <Box>
                        <Text>
                          Created at: {formatDate(cart.createdAt)}
                        </Text>
                        <Text>
                          Updated at: {formatDate(cart.updatedAt)}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                  {cart.videos.map((video, vIndex) => (
                    <Box display="flex" justifyContent="center">
                      <Box key={vIndex} mt={2} display="flex">
                        <Box>
                          <Image src={`https://img.youtube.com/vi/${video.vId}/maxresdefault.jpg`} w="150px" objectFit="cover" />
                        </Box>
                        <Box ml="80px">
                          <Text textAlign="start" display="flex">
                            <Text fontWeight="bold" mr="4">Title:</Text>
                            {video.title}
                          </Text>
                          <Text textAlign="start" display="flex">
                            <Text fontWeight="bold" mr="4">Video ID:</Text>
                            {video.vId}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>

              ))}
            </Box>
          </Box> */}

          <Box>

          </Box>


        </Box>
      </Box>
    </>
  )
}
export default Package