import {
    Box,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    TableCaption,
    TableContainer
} from '@chakra-ui/react';
import axios from 'axios'
import { useEffect, useState } from 'react';

const Invoice = () => {
    const userToken=localStorage.getItem("userToken")
    const [invoice,setInvoice]=useState()
    const getInvoive=()=>{
        axios(`${process.env.REACT_APP_SERVER}/get-invoice`,{
            headers:{
                Authorization:userToken
            }
        })
        .then((res)=>setInvoice(res.data))
    }
    useEffect(()=>{
        getInvoive()
    },[])
    return (
        <Box p="10px">
            <Heading mb={5}>Invoice</Heading>
            <Box  justifyContent="space-between">
                <Box >
                    <Box>
                        <Heading size="md">
                            REFlearn
                        </Heading>
                    </Box>
                    <Box>

                    <Box display="flex">
                    <Text fontWeight="bold">Invoice To:</Text><Text>{" "} {invoice?.name}</Text>
                    </Box>
                    </Box>
                </Box>
                <Box>
                    <Box display="flex">
                    <Text fontWeight="bold">Date:</Text><Text> {new Date(invoice?.transaction.createdAt).toLocaleDateString('en-GB')}</Text>
                    </Box>
                    
                    <Box display="flex">
                    <Text fontWeight="bold">Invoice No:</Text><Text>#{invoice?.transaction.id}</Text>
                    </Box>
                </Box>
            </Box>
            <TableContainer>
            <Table mt={5} mb={3} variant="simple">
            <TableCaption>Payment Of ₹{invoice?.transaction.amount} Received By {invoice?.transaction.type}</TableCaption>
                <Thead>
                    <Tr>
                        <Th>Item</Th>
                        <Th>Description</Th>
                        <Th>Quantity</Th>
                        <Th>Price</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td>{invoice?.userPackage.name}</Td>
                        <Td>{invoice?.userPackage.name}</Td>
                        <Td>1</Td>
                        <Td>₹ {invoice?.userPackage.price}</Td>
                    </Tr>
                    <Tr>
                        <Th></Th>
                        <Th></Th>
                        <Th>Tax</Th>
                        <Th>₹ 0</Th>
                    </Tr>
                    <Tr>
                        <Th></Th>
                        <Th></Th>
                        <Th>Discount</Th>
                        <Th>₹ -0</Th>
                    </Tr>
                    <Tr>
                        <Th></Th>
                        <Th></Th>
                        <Th>Total</Th>
                        <Th>₹ {invoice?.userPackage.price}</Th>
                    </Tr>
                    <Tr>
                        <Th></Th>
                        <Th></Th>
                        <Th>Balance</Th>
                        <Th>₹ 0</Th>
                    </Tr>
                </Tbody>
            </Table>
            </TableContainer>
            <Text textAlign="start" fontSize="sm">NOTE: This is compute generated receipt and does not require physical signature.</Text>
        </Box>
    );
};

export default Invoice