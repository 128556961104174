import { Element } from "react-scroll"
import { About, Base, Features, Package } from "../components/Home/AllPages"
import Navbar  from "../components/Navbar"
import Footer from "../components/Footer"

function Home(){
    return(
        <>
        <Navbar/>
        <Element name="home">
        <Base />
      </Element>
      <Element name="about">
        <About />
      </Element>
      <Element name="package">
        <Features />
      </Element>
      <Element name="contact">
        <Package />
      </Element>
      <Element name="contact">
        {/* <Contact /> */}
      </Element>
      <Footer/>
        {/* <TopCarousal/> */}
        </>
    )
}
export default Home