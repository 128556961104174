import { Box, Grid, Skeleton } from "@chakra-ui/react"

const VedioSkaleton=()=>{
    return(
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
      {[1, 2, 3, 4, 5, 6].map((item) => (
        <Box key={item} boxSize="full">
          <Skeleton />
        </Box>
      ))}
    </Grid>
  )
}
export default VedioSkaleton