import React, { useState } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
} from '@chakra-ui/react';

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi';
import {CgProfile} from 'react-icons/cg'
import {FaMoneyBillTransfer} from 'react-icons/fa6'
import {MdInsertPageBreak} from 'react-icons/md'
import {LuPackageOpen} from 'react-icons/lu'
import {SiGnuprivacyguard} from 'react-icons/si'
import {FaQuestion} from "react-icons/fa6"
import {FaWallet} from 'react-icons/fa'
import {BiLogOutCircle} from 'react-icons/bi'
import {VscWorkspaceUnknown} from 'react-icons/vsc'
import {FcAbout} from 'react-icons/fc'
import { Link } from 'react-router-dom';
import {FaUserTag} from 'react-icons/fa'
import axios from 'axios';

function handleLogout(){
    localStorage.setItem("adminToken","")
    localStorage.setItem("adminDetails","")
    window.location.href="/admin/login"
}
const LinkItems = [
  { name: 'Dashboard', icon: LuPackageOpen, src:'/admin/dashboard' },
  // { name: 'Agents', icon: FaUserTag, src:'/mypackages' },
  { name: 'Withdraw Requests', icon: FaUserTag, src:'/admin/withdrawreqeust' },
  { name: 'Users', icon: FiTrendingUp, src:'/admin/users' },
  { name: 'Add Packages', icon: FaMoneyBillTransfer, src:'/admin/package' },
  { name: 'Daily Adds', icon: MdInsertPageBreak, src:'/admin/adds' },
  // { name: 'Ptivacy Policy', icon: SiGnuprivacyguard, src:'/mypackages' },
  // { name: 'About Us', icon: VscWorkspaceUnknown, src:'/aboutus'},
  { name: 'FAQs', icon: FaQuestion, src:'/admin/faqs' },
  // { name: 'Profile', icon: CgProfile, src:'/mypackages' },
  { name: 'Logout', icon: BiLogOutCircle, onClick: () => {
    handleLogout()
  } },
];




export const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
      <Image src="/logo2.png"/>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link, index) => (
        <Link to={link.src} onClick={link.onClick ?? null}>
          <NavItem key={index} icon={link.icon}>
              {link.name}
          </NavItem>
        </Link>
      ))}
    </Box>
  );
};

export const NavItem = ({ icon, children, ...rest }) => {
  return (
    <Box as="a" href="#" style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};



export const MobileNav = ({ onOpen, ...rest }) => {
  
  const userDetails=JSON.parse(localStorage.getItem('adminDetails'))
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold">
        <Image src="logo2.png" w={["300px", "300px", "200px", ""]}/>
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} />

        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack >
                <Avatar
                  size={'sm'}
                  src={
                    'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  }
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                  mt="2"
                  >
                  <Text fontSize="sm" fontWeight="bold">{userDetails.first_name}</Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <MenuItem>Profile</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuItem>Billing</MenuItem>
              <MenuDivider />
              <MenuItem>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const SidebarWithHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="sm"
        >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {/* Content */}
        
      </Box>
    </Box>
  );
};

export default {SidebarWithHeader};
