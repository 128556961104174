// Invoice.js
import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Invoice from '../../components/Bill/Invoice';
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { MobileNav, SidebarContent } from "../../components/Dashboard/Sidebar";

// DownloadButton.js

const DownloadButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      Download
    </Button>
  );
};


const MyBill = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const invoiceData = {
    invoiceNumber: '12345',
    companyInfo: 'Your Company Info',
    purchaseItems: [
      { name: 'Item 1', price: '$20', tax: '$2', total: '$22' },
      { name: 'Item 2', price: '$30', tax: '$3', total: '$33' },
    ],
    total: '$100',
    tax: '$10',
  };

  const downloadPDF = async () => {
    const invoiceContainer = document.getElementById('invoice');

    if (!invoiceContainer) {
      console.error("Element with ID 'invoice' not found.");
      return;
    }

    const canvas = await html2canvas(invoiceContainer, { scale: 2 }); // Adjust scale if needed

    const pdf = new jsPDF('p', 'mm', 'a4'); // 'p' stands for portrait orientation
    const imgData = canvas.toDataURL('image/png');

    // Set the width and height to match the A4 dimensions
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('invoice.pdf');
  };
  return (
    <>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          <Box w={["100%","90%","90%","90%"]} m="auto" bg="white" p={2}>
            <Box display="flex" justifyContent="end">
              <DownloadButton onClick={downloadPDF} />
            </Box>
            <div id="invoice"> {/* Add the ID here */}
              <Invoice invoiceData={invoiceData} />
            </div>
            
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MyBill;