import { Table, Tbody, Tr, Td, Skeleton } from '@chakra-ui/react';

const TableSkalaton = () => {
  // Number of rows in the table
  const rowCount = 10;

  return (
    <Table>
      <Tbody>
        {Array.from({ length: rowCount }, (_, index) => (
          <Tr key={index}>
            <Td>
              <Skeleton height="10px" />
            </Td>
            {/* Add more Td components if your table has more columns */}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default TableSkalaton;
