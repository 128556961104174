/* eslint-disable no-unused-vars */
import { Route, Routes } from "react-router-dom"
import Login from "../pages/Login"
import Register from "../pages/Register"
import Home from "../pages/Home"
import PrivateRoute from "./privateRoute"
import DashboardDemo from "../pages/DashboardDome"
import MyPackages from "../pages/Dashboard/MyPackages"
import MyActivity from "../pages/Dashboard/MyActivity"
import FAQs from "../pages/Dashboard/FAQs"
import AboutUs from "../pages/Dashboard/AboutUs"
import NotFound from "../pages/PageNotFound"
import AdminPrivateRoute from "../admin/components/AdminPrivateRoute"
import AdminLogin from "../admin/pages/Login"
import Dashboard from "../admin/pages/Dashboard"
import Adds from "../admin/pages/Adds"
import Package from "../admin/pages/Package"
import Wallet from "../pages/wallet"
import CheckStatus from "../pages/CheckStatus"
import Multistep from "../pages/RegisterDemo"
import Packages from "../pages/Package"
import Refer from "../pages/Dashboard/Refer"
import WithdrawRequest from "../admin/pages/WithdrawRequest"
import Users from "../admin/pages/Users"
import MyBill from "../pages/Dashboard/MyBill"
import Profile from "../pages/Dashboard/Profile"
import Faq from "../admin/pages/Faq"
import TermsAndCondtion from "./Dashboard/TermsAndCondition.jsx"
import PrivacyPolicy from "../pages/Dashboard/PrivacyPolicy.jsx"
import MobileFaq from "./Mobile/MobileFaq.jsx"
import MobileMyBill from "./Mobile/MobileMyBill.jsx"
import MobileTermsAndConditions from "./Mobile/MobileTermsAndConditions.jsx"
import MobilePrivacyPolicy from "./Mobile/MobilePrivacyPolicy.jsx"
import MobileAboutUs from "./Mobile/MobileAboutUs.jsx"

function AllRoutes() {
    return (
        <Routes>
            <Route path="/*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            {/* <Route path="/" element={<Index/>}/> */}
            <Route path="/login" element={<Login />} />
            {/* <Route path="/register" element={<Register />} /> */}
            <Route path="/register" element={<Multistep />} />
            <Route path="/packages" element={<Packages />} />
            <Route path="/profile" element={<PrivateRoute> <Profile /></PrivateRoute>} />
            <Route path="/dashboarddemo" element={<PrivateRoute> <DashboardDemo /></PrivateRoute>} />
            {/* <Route path="/dashboard" element={<PrivateRoute> <SidebarWithHeader/></PrivateRoute>}/> */}
            <Route path="/mypackages" element={<PrivateRoute> <MyPackages /></PrivateRoute>} />
            <Route path="/myactivity" element={<PrivateRoute> <MyActivity /></PrivateRoute>} />
            <Route path="/refer" element={<PrivateRoute> <Refer /></PrivateRoute>} />
            <Route path="/mybill" element={<PrivateRoute> <MyBill /></PrivateRoute>} />
            <Route path="/mybill-app" element={ <MobileMyBill />} />
            <Route path="/aboutus" element={<PrivateRoute> <AboutUs /></PrivateRoute>} />
            <Route path="/aboutus-app" element={ <MobileAboutUs />} />
            <Route path="/terms-condition" element={<PrivateRoute> <TermsAndCondtion /></PrivateRoute>} />
            <Route path="/terms-condition-app" element={ <MobileTermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivateRoute> <PrivacyPolicy/></PrivateRoute>} />
            <Route path="/privacy-policy-app" element={ <MobilePrivacyPolicy/>} />
            <Route path="/faqs" element={<PrivateRoute> <FAQs /></PrivateRoute>} />
            <Route path="/faq-app" element={<MobileFaq />} />
            <Route path="/wallet" element={<PrivateRoute> <Wallet /></PrivateRoute>} />
            <Route path="/checkstatus/:id" element={ <CheckStatus/>} />
            <Route path="/success/:id" element={<PrivateRoute> <>Success</></PrivateRoute>} />
            <Route path="/failure" element={<PrivateRoute> <>failure</></PrivateRoute>} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/dashboard" element={<AdminPrivateRoute><Dashboard /></AdminPrivateRoute>} />
            <Route path="/admin/withdrawreqeust" element={<AdminPrivateRoute><WithdrawRequest /></AdminPrivateRoute>} />
            <Route path="/admin/users" element={<AdminPrivateRoute><Users /></AdminPrivateRoute>} />
            <Route path="/admin/adds" element={<AdminPrivateRoute><Adds /></AdminPrivateRoute>} />
            <Route path="/admin/package" element={<AdminPrivateRoute><Package /></AdminPrivateRoute>} />
            <Route path="/admin/faqs" element={<AdminPrivateRoute><Faq /></AdminPrivateRoute>} />
        </Routes>
    )
}
export default AllRoutes