/* eslint-disable no-unused-vars */
'use client'

import { useCallback, useEffect, useState } from 'react'
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  InputLeftAddon,
  InputGroup,
  FormHelperText,
  InputRightElement,
  Image,
} from '@chakra-ui/react'

import { useToast } from '@chakra-ui/react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
const Form1 = ({ updateFormData }) => {
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)
  return (
    <>
    <Image src="logo2.png" w={["80%", "50%", "40%", "40%"]} m="auto" mb="20px"/>
      <Heading w="100%" textAlign={'center'} fontWeight="normal" mb="2%">
        User Registration
      </Heading>
      <Flex>
        <FormControl mr="5%">
          <FormLabel htmlFor="first-name" fontWeight={'normal'}>
            First name
          </FormLabel>
          <Input id="first-name" placeholder="First name" onChange={(e) => updateFormData('first_name', e.target.value)} />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="last-name" fontWeight={'normal'}>
            Last name
          </FormLabel>
          <Input id="last-name" placeholder="First name" onChange={(e) => updateFormData('last_name', e.target.value)} />
        </FormControl>
      </Flex>
      <FormControl mt="2%">
        <FormLabel htmlFor="email" fontWeight={'normal'}>
          Email address
        </FormLabel>
        <Input id="email" type="email" onChange={(e) => updateFormData('email', e.target.value)} />
        <FormHelperText>We&apos;ll never share your email.</FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="password" fontWeight={'normal'} mt="2%">
          Password
        </FormLabel>
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            type={show ? 'text' : 'password'}
            placeholder="Enter password"
            onChange={(e) => updateFormData('password', e.target.value)}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick}>
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </>
  )
}

const Form2 = ({ updateFormData }) => {
  return (
    <>
      <Heading w="100%" textAlign={'center'} fontWeight="normal" mb="2%">
        User Details
      </Heading>
      <FormControl as={GridItem} colSpan={[6, 3, null, 2]}>
        <FormLabel
          htmlFor="mobile"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: 'gray.50',
          }}
          mt="2%">
          Mobile Number
        </FormLabel>
        <Input
          type="text"
          name="mobile"
          id="mobile"
          autoComplete="mobile"
          focusBorderColor="brand.400"
          shadow="sm"
          size="sm"
          w="full"
          rounded="md"
          onChange={(e) => updateFormData('mobile', e.target.value)}
        />
      </FormControl>

      <FormControl as={GridItem} colSpan={[6, 6, null, 2]}>
        <FormLabel
          htmlFor="city"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: 'gray.50',
          }}
          mt="2%">
          City
        </FormLabel>
        <Input
          type="text"
          name="city"
          id="city"
          autoComplete="city"
          focusBorderColor="brand.400"
          shadow="sm"
          size="sm"
          w="full"
          rounded="md"
          onChange={(e) => updateFormData('city', e.target.value)}
        />
      </FormControl>

      <FormControl as={GridItem} colSpan={[6, 3, null, 2]}>
        <FormLabel
          htmlFor="state"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: 'gray.50',
          }}
          mt="2%">
          State
        </FormLabel>
        <Input
          type="text"
          name="state"
          id="state"
          autoComplete="state"
          focusBorderColor="brand.400"
          shadow="sm"
          size="sm"
          w="full"
          rounded="md"
          onChange={(e) => updateFormData('state', e.target.value)}
        />
      </FormControl>

      <FormControl as={GridItem} colSpan={[6, 3, null, 2]}>
        <FormLabel
          htmlFor="gender"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: 'gray.50',
          }}>
          Gender
        </FormLabel>
        <Select
          id="gender"
          name="gender"
          autoComplete="gender"
          placeholder="Select option"
          focusBorderColor="brand.400"
          shadow="sm"
          size="sm"
          w="full"
          rounded="md"
          onChange={(e) => updateFormData('gender', e.target.value)}>
          <option>Male</option>
          <option>Female</option>
          <option>Other</option>
        </Select>
      </FormControl>
    </>
  )
}

const Form3 = ({ updateFormData }) => {
  return (
    <>
      <Heading w="100%" textAlign={'center'} fontWeight="normal">
        Agent Deatils
      </Heading>
      <SimpleGrid columns={1} spacing={6}>
        <FormControl as={GridItem} colSpan={[3, 2]}>
          <FormLabel
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: 'gray.50',
            }}>
            Agent Code
          </FormLabel>
          <InputGroup size="sm">
            <InputLeftAddon
              bg="gray.50"
              _dark={{
                bg: 'gray.800',
              }}
              color="gray.500"
              rounded="md">
              http://
            </InputLeftAddon>
            <Input
              type="tel"
              placeholder="Agent Code"
              focusBorderColor="brand.400"
              rounded="md"
              onChange={(e) => updateFormData('agent_code', e.target.value)}
            />
          </InputGroup>
        </FormControl>
      </SimpleGrid>
    </>
  )
}

export default function Multistep() {
  const toast = useToast()
  const navigate = useNavigate()
  const [referral, setReferral] = useState("")
  const [step, setStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [progress, setProgress] = useState(33.33)
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    mobile: '',
    city: '',
    state: '',
    gender: '',
    agent_code: null,
  });

  const updateFormData = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const chechOs = useCallback((referral) => {
    const regex = new RegExp(/android/gmi);
    const isMobile = regex.test(window.navigator.userAgent);
    
    if (isMobile) {
      const searchParams = new URLSearchParams(window.location.search);
      const medium = searchParams.get('medium');
      if (medium && medium === 'app') {
        sessionStorage.setItem('medium', 'app');
    }
      // window.location.href = `reflearn://registration?referred_by=${referral}`;

      setTimeout(() => {
        // open playstore url from here
      }, 1000);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Get the current URL's search string
    const searchParams = new URLSearchParams(window.location.search);
    // Get the value of the 'referral' parameter
    const referralParam = searchParams.get('referral');
    setReferral(referralParam)
    chechOs(referralParam);
  }, [chechOs]);



  const handleSubmit = async () => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      toast({
        title: 'Invalid Password',
        description: 'Password should be at least 8 characters long and contain at least one symbol, one capital letter, and one numeric character.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    // Validate mobile number
    const mobileRegex = /^[6-9]\d{9}$/;
    if (!mobileRegex.test(formData.mobile)) {
      toast({
        title: 'Invalid Mobile Number',
        description: 'Mobile number should be 10 digits long and start with a digit between 6 and 9.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    let url = ""
    if (referral) {
      url = `${process.env.REACT_APP_SERVER}/register-user?referral=${referral}`
    } else {
      url = `${process.env.REACT_APP_SERVER}/register-user`
    }

    setIsLoading(true)
    await axios.post(url, formData)
      .then((response) => {
        setIsLoading(false)
        if (response.status === 200) {
          toast({
            title: 'Account created.',
            description: "We've created your account for you.",
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
          navigate('/login')
        } if (response.status === 201) {
          toast({
            title: 'Exist Account.',
            description: "Account is already created.",
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
          navigate('/register')
        }
      })
      .catch((response) => {
        setIsLoading(false)
        toast({
          title: 'Something wrong.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        navigate('/register')
      })
  }
  return (
    <>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        maxWidth={800}
        p={6}
        m="10px auto"
        as="form">
        <Progress hasStripe value={progress} mb="5%" mx="5%" isAnimated></Progress>
        {step === 1 ? <Form1 updateFormData={updateFormData} /> : step === 2 ? <Form2 updateFormData={updateFormData} /> : <Form3 updateFormData={updateFormData} />}
        <ButtonGroup mt="5%" w="100%">
          <Flex w="100%" justifyContent="space-between">
            <Flex>
              <Button
                onClick={() => {
                  setStep(step - 1)
                  setProgress(progress - 33.33)
                }}
                isDisabled={step === 1}
                colorScheme="teal"
                variant="solid"
                w="7rem"
                mr="5%">
                Back
              </Button>
              <Button
                w="7rem"
                isDisabled={step === 3}
                onClick={() => {
                  setStep(step + 1)
                  if (step === 3) {
                    setProgress(100)
                  } else {
                    setProgress(progress + 33.33)
                  }
                }}
                colorScheme="teal"
                variant="outline">
                Next
              </Button>
            </Flex>
            {step === 3 ? (
              <Button
                w="7rem"
                colorScheme="red"
                variant="solid"
                onClick={() => {
                  handleSubmit()

                }}>
                Submit
              </Button>
            ) : null}
          </Flex>
        </ButtonGroup>
      </Box>
    </>
  )
}