// components/Home.jsx
import { Box, Button, HStack, Icon, Image, List, ListIcon, ListItem, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import TopCarousal from './TopCarousal';
import { RiGraduationCapFill } from "react-icons/ri";
import axios from 'axios';
import { FaCheckCircle } from 'react-icons/fa'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import '../../styles.css'
import { FaRupeeSign } from "react-icons/fa";
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { MdCastForEducation } from "react-icons/md";
import { Link } from 'react-router-dom';

const Base = () => {
    return (
        <Box>
            {/* <TopCarousal /> */}
            <Image src="https://t3.ftcdn.net/jpg/02/13/59/40/240_F_213594025_gAzvAKRuB2uYrfBc88HhwSZBb1M4eWWA.jpg" w="100%" h={["300px","450px","450px","450px"]} marginTop="-65px"/>
        </Box>
    );
};

const About = () => {
    return (
        <Box >
            <Box position="relative" display="inline-block" mt="20px" mb="20px">
                <Text as="h1">
                    About Us
                </Text>
                <Box
                    position="absolute"
                    bottom="0"
                    left="0"
                    width="70%"
                    height="4px"
                    borderRadius="5px"
                    backgroundColor="#d81b53"  // Set the color of the custom underline
                />
            </Box>
            <Box display={["", "flex", "flex", "flex"]} w={["100%", "80%", "80%", "80%"]} m="auto" justifyContent="space-between" alignItems="center" gap={["", "7px", "10px", "10px"]}>
                <Box w={["70%", "30%", "30%", "30%"]} m="auto">
                    <Image src="https://img.etimg.com/thumb/msid-81342207,width-640,height-480,imgsize-1032982,resizemode-4/learning-unlearning.jpg" borderRadius='full' boxSize='100%' boxShadow="xs" />
                </Box>
                <Box w={["100%", "70%", "70%", "70%"]}>
                    <Text lineHeight="1" color="#d81b53" fontWeight="bold" fontSize={["20px", "30px", "35px", "35px"]}>The Great Learn place</Text>
                    <Text lineHeight="1" fontWeight="bold" fontSize={["35px", "50px", "60px", "60px"]}>Academic Course</Text>
                    <Text fontSize={["14px", "16px", "18px", "18px"]} color="gray.400">Welcome to RefLearn, the ultimate platform where learning meets earning! At RefLearn, we believe in the power of knowledge and the potential of connections. Our platform is designed to empower individuals like you to not only expand your knowledge but also earn exciting bonuses by referring others to the world of learning.</Text>
                </Box>
            </Box>
        </Box>
    );
};
// CLIP-PATH: polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%);
// POSITION: RELATIVE;
const Features = () => {
    return (

        <Box mt="20px">
            <Box position="relative" display="inline-block" mt="20px" mb="20px">
                <Text as="h1">
                    Features
                </Text>
                <Box
                    position="absolute"
                    bottom="0"
                    left="0"
                    width="70%"
                    height="4px"
                    borderRadius="5px"
                    backgroundColor="#d81b53"  // Set the color of the custom underline
                />
            </Box>
            <Box w="100%" p="50px" bg="gray.50" display={["", "flex", "flex", "flex"]} gap="30px" justifyContent="space-around">
                <Box position="relative" mt="70px">
                    <Box borderRadius="10px" w="250px" p="10px" pt="50px" pb="20px" bg="white" m="auto" position={"relative"} clipPath="polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)"
                        _after={{
                            content: '""',
                            width: "30px",
                            height: "30px",
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            backgroundColor: "hsl(210deg 19.12% 88.5%)"
                        }}>
                        <Text lineHeight="1" mt="5px" color="#d81b53" fontWeight="bold" fontSize={["20px", "20px", "25px", "25px"]}>Flexible Packages</Text>
                        <Text textAlign="justify">Choose from a variety of packages that suit your learning needs. Whether you're a student, a professional, or someone looking to explore new skills, we have the right package for you.</Text>
                    </Box>
                    <Box w="100px" h="100px" borderRadius="full" bg="#d81b53" m="auto" top="0" position="absolute" left="50%" display="flex" justifyContent="center" alignItems="center"
                        // transform={{ translateX:"-50%", translateY:"-50%" }}
                        transform="translate(-50%, -50%)"
                    >
                        <Icon as={MdCastForEducation} fontSize="70px" color="white" />
                    </Box>
                </Box>

                <Box position="relative" mt="70px">
                    <Box borderRadius="10px" w="250px" p="10px" pt="50px" pb="20px" bg="white" m="auto" position={"relative"} clipPath="polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)"
                        _after={{
                            content: '""',
                            width: "30px",
                            height: "30px",
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            backgroundColor: "hsl(210deg 19.12% 88.5%)"
                        }}>
                        <Text lineHeight="1" mt="5px" color="#d81b53" fontWeight="bold" fontSize={["20px", "20px", "25px", "25px"]}>Learn and Earn</Text>
                        <Text textAlign="justify">Unlock a world of knowledge with our carefully curated video content tailored to your chosen package. From beginner to advanced levels, there's something for everyone. Plus, earn bonuses by referring friends, family, and colleagues.</Text>
                    </Box>
                    <Box w="100px" h="100px" borderRadius="full" bg="#d81b53" m="auto" top="0" position="absolute" left="50%" display="flex" justifyContent="center" alignItems="center"
                        // transform={{ translateX:"-50%", translateY:"-50%" }}
                        transform="translate(-50%, -50%)"
                    >
                        <Icon as={RiGraduationCapFill} fontSize="70px" color="white" />
                    </Box>
                </Box>


                <Box position="relative" mt="70px">
                    <Box borderRadius="10px" w="250px" p="10px" pt="50px" pb="20px" bg="white" m="auto" position={"relative"} clipPath="polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)"
                        _after={{
                            content: '""',
                            width: "30px",
                            height: "30px",
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            backgroundColor: "hsl(210deg 19.12% 88.5%)"
                        }}>
                        <Text lineHeight="1" mt="5px" color="#d81b53" fontWeight="bold" fontSize={["20px", "20px", "25px", "25px"]}>Referral Rewards</Text>
                        <Text textAlign="justify">Spread the word about RefLearn and earn exciting bonuses for every successful referral. It's a win-win – you help others discover a platform for learning, and you get rewarded for it!</Text>
                    </Box>
                    <Box w="100px" h="100px" borderRadius="full" bg="#d81b53" m="auto" top="0" position="absolute" left="50%" display="flex" justifyContent="center" alignItems="center"
                        // transform={{ translateX:"-50%", translateY:"-50%" }}
                        transform="translate(-50%, -50%)"
                    >
                        <Icon as={FaRupeeSign} fontSize="70px" color="white" />
                    </Box>
                </Box>

            </Box>
        </Box >
    );
};

const Package = () => {
    const [packages, setPackages] = useState([])
    const getPackage = () => {
        axios.get(`${process.env.REACT_APP_SERVER}/get-all-packages`)
            .then((res) => setPackages(res.data))
    }
    useEffect(() => {
        getPackage()
    }, [])
    return (
        <Box>
             <Box position="relative" display="inline-block" mt="20px" mb="20px">
                <Text as="h1">
                    Packages
                </Text>
                <Box
                    position="absolute"
                    bottom="0"
                    left="0"
                    width="70%"
                    height="4px"
                    borderRadius="5px"
                    backgroundColor="#d81b53"  // Set the color of the custom underline
                />
            </Box>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
            >
                {
                    packages && packages.map((item) => (

                        <SwiperSlide key={item.id}>
                            <Box
                                shadow="base"
                                borderWidth="1px"
                                alignSelf={{ base: 'center', lg: 'flex-start' }}
                                // borderColor={useColorModeValue('gray.200', 'gray.500')}
                                borderRadius={'xl'}>

                                <Box py={4} px={12}>
                                    <Text fontWeight="500" fontSize="2xl">
                                        {item.name}
                                    </Text>
                                    <HStack justifyContent="center">
                                        <Text fontSize="3xl" fontWeight="600">
                                            ₹
                                        </Text>
                                        <Text fontSize="5xl" fontWeight="900">
                                            {item.price}
                                        </Text>
                                        {/* <Text fontSize="3xl" color="gray.500">
                                    /month
                                </Text> */}
                                    </HStack>
                                </Box>
                                <VStack
                                    // bg={useColorModeValue('gray.50', 'gray.700')}
                                    py={4}
                                    borderBottomRadius={'xl'}>
                                    <List spacing={3} textAlign="start" px={12}>
                                        {
                                            item.features.map((el) => (
                                                <ListItem>
                                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                                    {el}
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                    <Box w="80%" pt={7}>
                                        <Link to="/login">
                                        <Button w="full" colorScheme="red" variant="outline" >
                                            Buy Package
                                        </Button>
                                        </Link>
                                    </Box>
                                </VStack>
                            </Box>
                        </SwiperSlide>

                    ))
                }
            </Swiper>
        </Box>
    );
};


export { Base, About, Features, Package };
