import React, { useEffect, useState } from "react";
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { MobileNav, SidebarContent } from "../../components/Dashboard/Sidebar";
import YouTube from "react-youtube"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import VideoSkaleton from "../../components/Home/VideoSkaleton";

const MyPackages = () => {
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const videoPlayers = {};
  const { isOpen, onOpen, onClose } = useDisclosure();
  const videoUrl = "https://www.youtube.com/embed/FxgM9k1rg0Q"
  const videoId = 'FxgM9k1rg0Q'
  const [videos,setVideos]=useState([])
  const [isLoading, setIsLoading]=useState(false)
  const userDetails = JSON.parse(localStorage.getItem('userDetails'))
  const navigate=useNavigate()
  
  // const videos = userDetails.userPackage.videos;

  const onVideoPlay = (videoId) => {
    if (playingVideoId && playingVideoId !== videoId) {
      // Pause the currently playing video if it's different from the new video
      const currentPlayer = videoPlayers[playingVideoId];
      if (currentPlayer) {
        currentPlayer.pauseVideo();
      }
    }
    setPlayingVideoId(videoId);
  };

  const onVideoReady = (event, videoId) => {
    videoPlayers[videoId] = event.target;
  };
  const getPackage=async()=>{
    const userToken=localStorage.getItem('userToken')
    setIsLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVER}/get-user-package`,{
      headers:{
          Authorization: userToken
      }
  }).then((res)=>{
    setIsLoading(false)
    setVideos(res.data.videos)})
  }
  useEffect(()=>{
    getPackage()
    // if(userDetails.userPackage==null){
    //   navigate('/packages')
    // }
  },[])
  useEffect(() => {
    // Pause the currently playing video when a new video starts playing
    if (playingVideoId) {
      videos.forEach((video) => {
        if (video.vId !== playingVideoId) {
          const player = videoPlayers[video.vId];
          if (player) {
            player.pauseVideo();
          }
        }
      });
    }
  }, [playingVideoId, videos]);

  return (
    <>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">

          {/* {
            videos.map((item) => (
              <YouTube
                videoId={item.vId}
                onPlay={onVideoPlay}
              />
            ))
          } */}

          <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]} gap={[3, 2, 3]} alignItems="center" justifyContent="center" m="auto">
            {isLoading? <VideoSkaleton/> : videos.map((item) => (
              <GridItem key={item.vId} m="auto">
                <YouTube
                  videoId={item.vId}
                  opts={{
                    height: "200",
                    width: "300",
                  }}
                  onReady={(event) => onVideoReady(event, item.vId)}
                  onPlay={() => onVideoPlay(item.vId)}
                  id={item.vId}
                />
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default MyPackages;