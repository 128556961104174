import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react"
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const PayModal = ({ id, referance, amount, userId }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [bankDetails, setBankDetails] = useState({})
    const toast = useToast()

    const getBank = () => {
        axios.get(`${process.env.REACT_APP_SERVER}/get-bank-admin/${referance}`)
            .then((res) => setBankDetails(res.data))
    }
    useEffect(() => {
        getBank()
    }, [])

    const onSubmit = (e,status, am, userId) => {
        if(e){
            e.preventDefault()
        }
        let amount=null
        if(am){
            amount=am
        }
        
        axios.patch(`${process.env.REACT_APP_SERVER}/update-withdraw-request/${id}`,{
            status:status,
            amount,
            userId
        })
            .then((res) => {
                toast({
                    title: 'Success',
                    description: 'Transaction Completed',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                })
                onClose()
                window.location.reload()
            })
    }
    return (
        <>
            <Button onClick={onOpen}>Pay</Button>

            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>User Payment</ModalHeader>
                    <ModalCloseButton />
                    <form action="" onSubmit={(e)=>onSubmit(e,"completed", amount, userId)}>
                        <ModalBody>

                            <FormControl>
                                <FormLabel>Account Holders Name</FormLabel>
                                <Input
                                    value={bankDetails.account_holder_name
                                    }
                                    readOnly
                                />
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>Bank</FormLabel>
                                <Input
                                    value={bankDetails.bank_name}
                                    readOnly
                                />
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>Account Number</FormLabel>
                                <Input
                                    value={bankDetails.account_number}
                                    readOnly
                                />
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>IFSC Code</FormLabel>
                                <Input
                                    value={bankDetails.ifsc_code}
                                    readOnly
                                />
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>Amount</FormLabel>
                                <Input
                                    value={amount}
                                    readOnly
                                />
                            </FormControl>

                            <FormControl isRequired isInvalid={errors.example} mt={4}>
                                <FormLabel htmlFor="example">Transaction Id</FormLabel>
                                <Input type="text"
                                    {...register("example", { required: "This field is required" })}
                                />
                                <FormErrorMessage>{errors.example && errors.example.message}</FormErrorMessage>
                            </FormControl>

                        </ModalBody>

                        <ModalFooter>
                            <Button type="submit" colorScheme='blue' >Complete Payment</Button>
                            <Button colorScheme='red' onClick={()=>onSubmit(null,"failed", amount, userId)}>Failed</Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}

export default PayModal