/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Icon, Image, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import axios from "axios"
import { Link, useNavigate, useParams } from "react-router-dom"
import { MdOutlineDone } from "react-icons/md";
const isMedium = () => {
    return !!sessionStorage.getItem('medium');
}
const goToApp = () => {
    window.location.href = 'reflearn://home';

    setTimeout(() => {
        // open playstore url from here
        window.location.href = 'https://play.google.com/store/apps/details?id=com.paarshinfotech.reflearn'
    }, 3000);
}
const CheckStatus = () => {
    const { id } = useParams()
    const [isLoading, setIsLoding] = useState(false)
    const [status, setStatus] = useState(false)
    const navigate = useNavigate()
    const check = async () => {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'))
        const packageDetails = localStorage.getItem('packageId')
        const userToken = localStorage.getItem('userToken')
        setIsLoding(true)
        axios.post(`${process.env.REACT_APP_SERVER}/status/${id}`, {
            packageId: packageDetails,
            userId: userDetails.id
        })
            .then(async (res) => {
                if (res.data.success) {
                    setIsLoding(false)
                    setStatus(true)
                    axios.post(`${process.env.REACT_APP_SERVER}/add-transaction`, {
                        userId: userDetails.id,
                        code: res.data.code,
                        amount: res.data.data.amount / 100,
                        merchant_id: res.data.data.merchantId,
                        merchant_transaction_id: res.data.data.merchantTransactionId,
                        type: res.data.data.paymentInstrument.type,
                        transaction_id: res.data.data.transactionId
                    }).then((res) => {
                        axios.patch(`${process.env.REACT_APP_SERVER}/update-user`, {
                            package: packageDetails,
                        }, {
                            headers: {
                                Authorization: userToken
                            }
                        }
                        ).then((res) => {
                            localStorage.setItem("userDetails", JSON.stringify(res.data.user))

                            axios.get(`${process.env.REACT_APP_SERVER}/reward-distribution`, {
                                headers: {
                                    Authorization: userToken
                                }
                            })
                        })
                    })

                    if (isMedium()) {

                        goToApp();
                    } else {
                        setTimeout(() => {
                            // open playstore url from here
                            navigate('/mypackages')
                            // window.location.href = 'https://play.google.com/store/apps/details?id=com.salonkatta.crm'
                        }, 3000);
                    }
                } else {
                    setStatus(false)
                    setIsLoding(false)

                }
            })
            .catch((err) => {
                setIsLoding(false)
                setStatus(false)
            })
    }
    useEffect(() => {
        // window.location.href = 'reflearn://home';
        check()
    }, [])
    return (
        <>
            {
                isLoading ?
                    <Box m="auto">
                        <Image src="https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca.gif" />
                        <Text>Checking status</Text>
                    </Box>
                    :
                    status ?
                        <>
                            <Box borderRadius="50%" bg="green" w="40px" m="auto">
                                <Icon as={MdOutlineDone} coor="white" fontSize="25px" />
                            </Box>
                            <Text>Payment Success</Text>
                            {
                                isMedium() ? (
                                    <a href="reflearn://home">
                                        <Button>Go to App</Button>
                                    </a>
                                ) : (
                                    <Link to="/mypackages">
                                        <Button>Go To Packages</Button>
                                    </Link>
                                )
                            }
                        </>
                        :
                        <Text>Payment Faild</Text>
            }
        </>
    )
}
export default CheckStatus