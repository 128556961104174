import React, { useEffect, useState } from "react";
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react';
import { MobileNav, SidebarContent } from "../components/AdminSidebar"
import axios from "axios";
import PayModal from "../components/WithdrawRequest/PayModal";

const WithdrawRequest = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [request, setRequest] = useState([])

  const getRequest = () => {
    axios.get(`${process.env.REACT_APP_SERVER}/get-withdraw-request`)
      .then((res) => setRequest(res.data))
  }
  useEffect(() => {
    getRequest()
  }, [])
  return (
    <>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          {/* Content */}
          <TableContainer bg="white" w={["95%","85%","80%","80%",]} m="auto">
            <Table size='sm'>
              <Thead>
                <Tr>
                  <Th>Sr.No.</Th>
                  <Th>User Id</Th>
                  <Th isNumeric>Amount</Th>
                  <Th isNumeric>Date</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  request.map((el, i) => (
                    <Tr>
                      <Td>{i+1}</Td>
                      <Td>{el.userId}</Td>
                      <Td isNumeric>{el.amount}</Td>
                      <Td isNumeric>{new Date(el.createdAt).toLocaleDateString('en-GB')}</Td>
                      <Td><PayModal id={el.id} referance={el.referance_id} amount={el.amount} userId={el.userId}/></Td>
                    </Tr>
                  ))
                }

              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  )
}
export default WithdrawRequest