import React, { useEffect, useState } from "react";
import {
  Box,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import { MobileNav, SidebarContent } from "../../components/Dashboard/Sidebar";
import axios from "axios";
import { IoMdAdd } from "react-icons/io";
import { LuMinus } from "react-icons/lu";
const FAQs = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [categories, setCategories] = useState([])
  const [faq, setFaq] = useState([])
  const [selected, setSelected] = useState("")
  const getCategories = () => {
    axios(`${process.env.REACT_APP_SERVER}/get-faq-categories`)
      .then((res) => setCategories(res.data))
  }
  const getFaq = () => {
    axios(`${process.env.REACT_APP_SERVER}/get-faq?categories=${selected}`)
      .then((res) => setFaq(res.data))
  }
  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    // Set initial value for 'selected' when 'categories' state is updated
    if (categories.length > 0) {
      setSelected(categories[0].title);
    }
  }, [categories]);

  useEffect(() => {
    getFaq();
  }, [selected]);

  const handleTabChange = (index, categories) => {
    setSelected(categories.title)
  };

  return (
    <>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          {/* Content */}
          <Tabs onChange={(index) => handleTabChange(index, categories[index])} isLazy>
            <div
              className="custom-scrollbar"
              style={{
                overflowX: 'auto',
                whiteSpace: 'nowrap',
              }}
            >
              <TabList
                css={{
                  display: 'flex',
                  gap: '8px',
                  paddingRight: '16px',
                }}
              >
                {categories.map((category, index) => (
                  <Tab key={index} _selected={{ color: 'white', bg: 'teal.500' }}>
                    {category.title}
                  </Tab>
                ))}
              </TabList>
            </div>

          </Tabs>
          <Accordion allowToggle w={["100%", "80%", "70%", "70%"]} m="auto" mt="40px">
            {
              faq.map((el) => (
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex='1' textAlign='left'>{el.question}</Box>
                          {isExpanded ? (
                            <Icon as={LuMinus} />
                          ) : (
                            <Icon as={IoMdAdd} />
                          )}
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>{el.answer}</AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))
            }
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

export default FAQs;