import React from "react";
import {
  Box,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Heading, List, ListItem
} from '@chakra-ui/react';
import { MobileNav, SidebarContent } from "../../components/Dashboard/Sidebar";


const AboutUs = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          {/* Content */}
          <Box mx="auto" p="4">
            <Heading textAlign="start" mb="4" fontSize="2xl">
              About RefLearn
            </Heading>

            <Text textAlign="start">
              Welcome to RefLearn, the ultimate platform where learning meets earning! At RefLearn, we believe in the power of knowledge and the potential of connections. Our platform is designed to empower individuals like you to not only expand your knowledge but also earn exciting bonuses by referring others to the world of learning.
            </Text>

            <Heading mt="4" textAlign="start" mb="2" fontSize="xl">
              Our Mission
            </Heading>

            <Text textAlign="start">
              At RefLearn, our mission is to create a community where learning is not just a journey but an adventure. We are committed to providing a platform that seamlessly blends education with the opportunity to earn rewards. We aim to foster a culture of continuous learning and sharing, ensuring that everyone can benefit from the collective knowledge within our community.
            </Text>

            <Heading mt="4" textAlign="start" mb="2" fontSize="xl">
              Why RefLearn?
            </Heading>

            <List styleType="decimal" ml="4" textAlign="start">
              <ListItem>
                <strong>Learn and Earn:</strong> Unlock a world of knowledge with our carefully curated video content tailored to your chosen package. From beginner to advanced levels, there's something for everyone. Plus, earn bonuses by referring friends, family, and colleagues.
              </ListItem>

              <ListItem>
                <strong>Community-Centric Approach:</strong> Join a community of like-minded individuals who are passionate about learning and sharing knowledge. Connect with others, participate in discussions, and enhance your learning experience.
              </ListItem>

              <ListItem>
                <strong>Flexible Packages:</strong> Choose from a variety of packages that suit your learning needs. Whether you're a student, a professional, or someone looking to explore new skills, we have the right package for you.
              </ListItem>

              <ListItem>
                <strong>Referral Rewards:</strong> Spread the word about RefLearn and earn exciting bonuses for every successful referral. It's a win-win – you help others discover a platform for learning, and you get rewarded for it!
              </ListItem>
            </List>

            <Heading mt="4" textAlign="start" mb="2" fontSize="xl">
              How It Works
            </Heading>

            <List styleType="decimal" ml="4" textAlign="start">
              <ListItem>
                <strong>Sign Up:</strong> Create your account on RefLearn to access a world of knowledge.
              </ListItem>

              <ListItem>
                <strong>Choose Your Package:</strong> Select the package that aligns with your learning goals and interests.
              </ListItem>

              <ListItem>
                <strong>Learn:</strong> Dive into our extensive library of video content and start your learning journey.
              </ListItem>

              <ListItem>
                <strong>Refer and Earn:</strong> Share your unique referral link with friends and colleagues. When they join RefLearn, you both earn bonuses!
              </ListItem>
            </List>

            <Heading mt="4" textAlign="start" mb="2" fontSize="xl">
              Join RefLearn Today
            </Heading>

            <Text textAlign="start">
              Embark on a journey of knowledge and rewards with RefLearn. Sign up now and become part of a community that values learning, sharing, and earning. Together, let's redefine the way we learn and grow.
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AboutUs;