import { useToast } from "@chakra-ui/react";
import { Navigate } from "react-router-dom";
const PrivateRoute = ({ children }) => {
    const toast = useToast()
    let token = localStorage.getItem('userToken')
    let userDetails = ""
  
    if (!token) {
      toast({
        title: 'Login required',
        description: 'You are not logged in',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      })
      return <Navigate to='/login' />
    }
    userDetails=JSON.parse(localStorage.getItem('userDetails'))
    if(!userDetails.package){
      toast({
        title: 'Please Select Package',
        description: 'You have to subscribe first.',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      })
      return <Navigate to='/packages'/>
    }
    return children
  }

export default PrivateRoute