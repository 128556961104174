/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Drawer, DrawerContent, Icon, Input, InputGroup, InputRightAddon, ListItem, OrderedList, Text, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react"
import { MobileNav, SidebarContent } from "../../components/Dashboard/Sidebar";
import { useEffect, useRef, useState } from "react";
import { IoMdCopy } from "react-icons/io";

const Refer = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [url, setUrl] = useState("")
    const referralLinkInputRef = useRef(null);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'))
    const toast = useToast()
    useEffect(() => {
        const referralLink = `http://localhost:3000/register?referral=${userDetails.referral_code}`;
        setUrl(referralLink);
        referralLinkInputRef.current.value = referralLink;
    }, []);

    const handleCopy = () => {
        const referralLinkInput = referralLinkInputRef.current;
    
        if (referralLinkInput) {
            // Create a temporary input element
            const tempInput = document.createElement("input");
    
            // Set the input value to the text you want to copy
            tempInput.value = referralLinkInput.value;
    
            // Append the input element to the DOM
            document.body.appendChild(tempInput);
    
            // Select the text in the input element
            tempInput.select();
            tempInput.setSelectionRange(0, 99999); // For mobile devices
    
            // Execute the copy command
            document.execCommand("copy");
    
            // Remove the temporary input element from the DOM
            document.body.removeChild(tempInput);
    
            // Provide feedback
            toast({
                position: 'top',
                title: 'Link Copied',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        }
    };
    
    return (
        <>
            <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
                <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
                <Drawer
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    returnFocusOnClose={false}
                    onOverlayClick={onClose}
                    size="full">
                    <DrawerContent>
                        <SidebarContent onClose={onClose} />
                    </DrawerContent>
                </Drawer>
                {/* mobilenav */}
                <MobileNav onOpen={onOpen} />
                <Box ml={{ base: 0, md: 60 }} p="4">

                    <Box w={["90%", "80%", "80%"]} m="auto" p={["10px", "20px", "30px",]} borderRadius="10px" bg="pink.50" boxShadow="md">
                        <Text as="h2" color="pink.500">Welcome To ₹EFLearn Referral Program!</Text>
                        <Text as="h5" color="pink.500">Invite your friends and earn instant ₹ 2400 and earn upto ₹ 3200 on one referral</Text>
                    </Box>
                    <Box w={["90%", "70%", "60%"]} m="auto" border="1px solid gray" borderRadius="5px" p="30px" mt="20px" bg="white">
                        <Text as="h6" textAlign="start" mb="25px"> Shere this link To refer</Text>
                        <InputGroup bg="gray.100">
                            <Input color="gray" ref={referralLinkInputRef} isReadOnly variant="unstyled" />
                            <InputRightAddon bg="none" border="none"><Icon color="teal.600" fontSize="25px" onClick={handleCopy} as={IoMdCopy} /></InputRightAddon>
                        </InputGroup>
                    </Box>
                    <Box w={["100%", "80%", "80%"]} m="auto"  mt="50px" borderRadius="5px">
                        <Text as="h6" textAlign="start" mb="25px"> Criteria for new referrals</Text>
                        <Box bg="gray.100"  textAlign="start">
                            <OrderedList>
                                <ListItem>The person you’re sharing the link to cannot have any existing account at This App.</ListItem>
                                <ListItem>Only You got Reward When user Purchase a package</ListItem>
                            </OrderedList>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Refer


