/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Box,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Select,
  FormLabel,
  Button,
  Input,
  useToast,
} from '@chakra-ui/react';
import { MobileNav, SidebarContent } from "../components/Dashboard/Sidebar";
import axios from "axios";
import { GiWallet } from "react-icons/gi"
import { FaArrowCircleDown, FaArrowCircleRight } from "react-icons/fa";
import { MdCancel } from "react-icons/md"
import AddBank from "../components/Wallet/AddBank";

const Wallet = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [balance, setBalance] = useState(0)
  const [loading2] = useState(false);
  const [banks, setbanks] = useState([])
  const [history, setHistory] = useState([])
  const [bank_details, setBank_Details] = useState("")
  const [amount, setAmount] = useState(0)
  const toast = useToast()
  const userToken=localStorage.getItem('userToken')


  const getWalletAmount = async () => {
    const res = await axios.get(`${process.env.REACT_APP_SERVER}/get-wallate-point`, {
      headers:{
          Authorization: userToken
      }
  })
    setBalance(res.data.totalAmount)
  }

  const getBank = async () => {
    await axios.get(`${process.env.REACT_APP_SERVER}/get-bank`,{
      headers:{
          Authorization: userToken
      }
  }).then((res) => setbanks(res.data))
  }

  const getHistory = async () => {
    
   
    await axios.get(`${process.env.REACT_APP_SERVER}/get-transaction-history`, {
      headers:{
          Authorization: userToken
      }
  }).then((res) => setHistory(res.data.history))
  }
  useEffect(() => {
    getWalletAmount()
    getBank()
    getHistory()
  }, [])
  //  const data ={
  //      name: 'Waleed',
  //      amount: 1,
  //      number: '7498608775',
  //      MUID: "MUID" + Date.now(),
  //      transactionId: 'T' + Date.now(),
  //  }

  //    const handlePayment = (e)=>{
  //     //  newPayment()
  //      setLoading2(true);
  //      axios.post(`${process.env.REACT_APP_SERVER}/api/payment`, {...data}).then(res => {  
  //       console.log("res",res);
  //       window.location.href = res.data;
  //      setTimeout(() => {
  //          setLoading2(false);
  //      }, 1500);
  //      })
  //      .then((res)=>console.log(res))
  //      .catch(error => {
  //          setLoading2(false)
  //          console.error(error);
  //      });   
  //  }

  const handleWithdraw = async () => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'))
    if (bank_details === "") {
      toast({
        title: 'Please Select Bank',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        position: 'top',
      })
    } else if (amount === 0) {
      toast({
        title: 'Please Enter Amount to withdraw',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        position: 'top',
      })
    } else if (amount < 100) {
      toast({
        title: 'Minumum amount is 100 rs',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        position: 'top',
      })
    } else {
      await axios.post(`${process.env.REACT_APP_SERVER}/add-wallate-point`,
        {
          type: 'withdraw',
          amount: amount,
          bank_details: bank_details,
          userId: userDetails.id,
          transaction_type: 'dr'
        }).then((res) => {
          if(res.status===200){
            toast({
              title: 'Withdraw Request Sended Successfully',
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top',
            })
          }else if(res.status===203){
            toast({
              title: 'Please Enter Correct withdraw Amount',
              status: 'warning',
              duration: 3000,
              isClosable: true,
              position: 'top',
            })
          }else if(res.status===202){
            toast({
              title: 'You are inactive',
              description:' You can not withdraw amount if you are inactive, Please refer someone to activate yourself',
              status: 'warning',
              duration: 3000,
              isClosable: true,
              position: 'top',
            })
          }
        })
    }
  }
  return (
    <>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          {/* Content */}
          <Box display="flex" gap={["10", "10", "10", "20"]}>
            <Box w={["90%", "80%", "50%", "55%"]} bg="white" m="auto" p="5" borderRadius="5">
              <Text fontSize="22px" borderBottom="1px solid gray" fontWeight="bold">Wallet Amount</Text>
              <Box display="flex" gap="6" justifyContent="center" alignItems="center">
                <Icon as={GiWallet} color="teal.600" fontSize="50px" />
                <Text fontSize="30px">₹ {balance.toFixed(2)} /-</Text>
              </Box>
              <FormLabel>Select Bank</FormLabel>
              <Select onChange={(e) => setBank_Details(e.target.value)}>
                <option value="">Select Back</option>
                {
                  banks && banks.map((item) => (
                    <option value={item.id}>{item.bank_name}</option>
                  ))
                }
              </Select>
              <Box display="flex" justifyContent="end" mt="5">

                <AddBank />
              </Box>
              <FormLabel>Enter Withdraw Amount</FormLabel>
              <Input mt="5" type="number" placeholder="Minimum amount 100" border="none" borderBottom="1px solid gray" onChange={(e) => setAmount(e.target.value)} />
              <Button bg="teal.600" color="white" w="80%" mt="5" _hover={{ bg: "teal.800" }} onClick={handleWithdraw} isLoading={loading2}>Withdraw</Button>
            </Box>
            <Box w={["90%", "80%", "50%", "45%"]} borderRadius="5" bg="white" p="5" >
              <Text fontSize="22px" borderBottom="1px solid gray" fontWeight="bold">Transaction History</Text>
              {
                history && history.map((item) => ( 
                  <Box borderRadius="5" boxShadow="md" p="5" pb="0" mb="2" bgColor={item.status === "completed" ? "green.50" : item.status === "pending" ? "orange.100" : "red.100"}>
                    <Box display="flex" justifyContent="space-between">
                      <Text fontWeight="bold">{item.type}</Text>
                      <Text fontWeight="bold" color={item.status === "completed" ? "green" : item.status === "pending" ? "orange" : "red"}>{item.status}</Text>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box>
                        <Icon as={item.type === "watchPoints" ? FaArrowCircleDown : item.type === "withdraw" ? FaArrowCircleRight : MdCancel} color={item.status === "completed" ? "green" : item.status === "pending" ? "orange" : "red"} fontSize="40px" />
                        <Text mt="5" lineHeight="0" textDecoration="underline" _hover={{ color: "blue.400" }}>Know More</Text>
                      </Box>
                      <Box >
                        <Text lineHeight="1" fontWeight="bold">{item.amount} {item.transaction_type}</Text>
                        <Text lineHeight="1">{new Date(item.createdAt).toLocaleDateString('en-GB')}</Text>
                      </Box>
                    </Box>
                  </Box>
                ))
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
export default Wallet