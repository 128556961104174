import { Box, Button, FormLabel, Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, useDisclosure, useToast } from "@chakra-ui/react"
import axios from "axios"
import { useEffect, useState } from "react"
import { IoMdAddCircleOutline } from 'react-icons/io'
import AddCategoriesModel from "./AddCategoriesModal"

const init = {
    question: "",
    answer: "",
    categories: ""
}
const AddModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [formData, setFormData] = useState(init)
    const [categories, setCategories]=useState([])
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(false)
    const handelFormData = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const getCategories=()=>{
        axios(`${process.env.REACT_APP_SERVER}/get-faq-categories`)
        .then((res)=>setCategories(res.data))
    }
    useEffect(()=>{
        getCategories()
    },[])
    const handleSubmit = async (e) => {

        e.preventDefault()
        setIsLoading(true)
        const res= await axios.post(`${process.env.REACT_APP_SERVER}/add-faq`, formData)
        if(res.status==200){
            setIsLoading(false)
            onClose()
            toast({
                title: ' FAQ Added ',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top',
              })
        }
        window.location.reload()
    }
    return (
        <>
            <Button bg="teal.500" color="white" onClick={onOpen} _hover={{ bg: "teal.600" }}>
                <Icon as={IoMdAddCircleOutline} boxSize={5} mr={3} />
                Add New FAQ</Button>

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Adds New FAQ's</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form action="" id="scheduleAd"  onSubmit={handleSubmit}>
                            <Box display="flex" gap="7px" justifyContent="center" alignItems="center">
                            <Select name="categories" onChange={(e) => handelFormData(e)} required>
                                <option value="">Select Category</option>
                                {
                                    categories.map((el)=>(
                                        <option value={el.title}>{el.title}</option>
                                    ))
                                }
                            </Select>
                            <AddCategoriesModel/>
                            </Box>
                            <FormLabel>Question</FormLabel>
                            <Input name="question" type="text" onChange={(e) => handelFormData(e)} required/>
                            <FormLabel>Answer</FormLabel>
                            <Input name="answer" type="text" onChange={(e) => handelFormData(e)} required/>
                        </form>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' type="submit" mr={3} isLoading={isLoading} loadingText={"Saveing..."} form="scheduleAd">
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AddModal