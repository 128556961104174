import React, { useEffect, useState } from "react";
import {
  Box,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Grid,
} from '@chakra-ui/react';
import { MobileNav, SidebarContent } from "../components/AdminSidebar"
import axios from "axios";
import { CiVideoOn } from "react-icons/ci";
import { RiUserShared2Line } from "react-icons/ri";
import { TbUserSearch } from "react-icons/tb";
import { LuIndianRupee } from "react-icons/lu";

const Dashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [calculation, setCalculation] = useState({})
  const [history, setHistory] = useState([])
  const getCalculations = async () => {
    await axios.get(`${process.env.REACT_APP_SERVER}/get-dashboard-calculations`)
      .then((res) => setCalculation(res.data))
  }

  const getHistory = async () => {
    await axios.get(`${process.env.REACT_APP_SERVER}/get-history?page=1&limit=5`)
      .then((res) => setHistory(res.data.history))
  }
  useEffect(() => {
    getCalculations()
    getHistory()
  }, [])
  return (
    <>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          {/* Content */}
          <Box display={["block", "block", "flex", "flex"]} gap="15px">
            <Grid templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)"]} gap="15px" w={["80%", "80%", "65%", "65%"]} mx="auto">
              <Box bgGradient='linear(to-r, purple.400, purple.100)' borderRadius="10px" color="purple" display="flex" justifyContent="center" alignItems="center">
                <Box>
                  <Box ml="-40px" mt={["", "-45px", "-45px", "-45px"]} alignItems="start" display="flex">
                    <Icon fontSize={["35px", "45px", "45px", "45px"]} fontWeight="normal" color="purple.200" as={CiVideoOn} />
                  </Box>
                  <Box >
                    <Text fontWeight="bold" fontSize={["35px", "45px", "45px", "45px"]}>₹ {calculation.adsPoints}</Text>
                    <Text fontWeight="bold" lineHeight="0">Advertisment Collection</Text>
                  </Box>
                </Box>
              </Box>

              <Box bgGradient='linear(to-r, blue.400, blue.100)' borderRadius="10px" color="blue.700" display="flex" justifyContent="center" alignItems="center">
                <Box>
                  <Box ml="-40px" mt={["", "-45px", "-45px", "-45px"]} alignItems="start" display="flex">
                    <Icon fontSize={["35px", "45px", "45px", "45px"]} fontWeight="normal" color="blue.200" as={RiUserShared2Line} />
                  </Box>
                  <Box >
                    <Text fontWeight="bold" fontSize={["35px", "45px", "45px", "45px"]}>₹ {calculation.rewardPoints}</Text>
                    <Text lineHeight="0" fontWeight="bold">Referral Collection</Text>
                  </Box>
                </Box>
              </Box>

              <Box bgGradient='linear(to-r, yellow.400, yellow.100)' borderRadius="10px" color="yellow.700" display="flex" justifyContent="center" alignItems="center">
                <Box>
                  <Box ml="-40px" mt={["", "-45px", "-45px", "-45px"]} alignItems="start" display="flex">
                    <Icon fontSize={["35px", "45px", "45px", "45px"]} fontWeight="normal" color="yellow.100" as={TbUserSearch} />
                  </Box>
                  <Box >
                    <Text fontWeight="bold" fontSize={["35px", "45px", "45px", "45px"]}>₹ {calculation.agentPoints}</Text>
                    <Text lineHeight="0" fontWeight="bold">Agent Commission</Text>
                  </Box>
                </Box>
              </Box>

              <Box bgGradient='linear(to-r, pink.400, pink.100)' borderRadius="10px" color="pink.700" display="flex" justifyContent="center" alignItems="center">
                <Box>
                  <Box ml="-40px" mt={["", "-45px", "-45px", "-45px"]} alignItems="start" display="flex">
                    <Icon fontSize={["35px", "45px", "45px", "45px"]} fontWeight="normal" color="pink.200" as={LuIndianRupee} />
                  </Box>
                  <Box >
                    <Text fontWeight="bold" fontSize={["35px", "45px", "45px", "45px"]}>₹ {calculation.revenues}</Text>
                    <Text lineHeight="0" fontWeight="bold">Total Revenue</Text>
                  </Box>
                </Box>
              </Box>

            </Grid>
            <Box h="450px" w={["80%", "80%", "35%", "35%"]} border="1px solid gray.200" mx="auto" boxShadow="xs">
              <Box w="100%" h="40px" bg="white" boxShadow="xs">
                <Text fontWeight="bolt" fontSize="20px" >Latest History</Text>
                {
                  history.map((el) => (
                    <Box bg="white" borderRadius="5px" w="95%" m="auto" p="10px"  mb="10px" border="1px solid gray.200" boxShadow="base">
                      <Box display="flex" justifyContent="space-between">
                        <Box display="flex">
                          <Text color="gray.400">User Id:_ </Text>
                        <Text>{el.userId}</Text>
                        </Box>
                        <Box display="flex">
                        <Text color="gray.400">Date: </Text>
                        <Text> {new Date(el.createdAt).toLocaleDateString('en-GB')}</Text>
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between" lineHeight="0">
                        <Text>₹ {el.amount}</Text>
                        <Text fontWeight="bold" color={el.transaction_type==="cr"? "green":"red"}>{el.transaction_type==="dr"? "Debited" : el.transaction_type==="cr"? "Credited":"Someting Worng"}</Text>
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
export default Dashboard