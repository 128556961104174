/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";

const DashboardDomo=()=>{
    const [isOpen, setIsOpen] = useState(window.innerWidth >= 768);
    const [isToggled, setIsToggled]=useState(false)
  const toggleSidebar = () => {
    setIsToggled(!isToggled);
  };

  window.addEventListener('resize', () => {
    if (window.innerWidth < 768) {
      setIsOpen(false); // Close the sidebar when the screen is small
    } else {
      setIsOpen(true); // Open the sidebar on larger screens
    }
  });

  return (
    <div className={`d-flex ${isOpen ? 'toggled' : ''}`}>
        
      <nav id="sidebar" className={`bg-light ${isToggled ? 'd-none' : 'w-30'} ${isOpen? 'w-30' : 'w-70'}`}>
        <div className="position-sticky">
          <ul className="nav flex-column">
          <button>Close</button>
            <li className="nav-item">
              <a className="nav-link active" href="#">
                Dashboard
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Orders
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Products
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div id="content">
        <button className={`btn btn-primary d-md-none ${isToggled ? 'd-none' : 'w-30'}`} onClick={toggleSidebar}>
          Toggle Sidebar
        </button>
        <main className="p-4">
          {/* Your content goes here */}
        </main>
      </div>
    </div>
  );
}
export default DashboardDomo