/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Box,
    useColorModeValue,
    Drawer,
    DrawerContent,
    useDisclosure,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Button,
} from '@chakra-ui/react';
import { MobileNav, SidebarContent } from "../components/AdminSidebar"
import axios from "axios";
import TableSkalaton from "../components/TableScalaton";

const Users = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [user, setUser] = useState([])
    const [count,setCount]=useState(0)
    const [page,setPage]=useState(1)
    const [isLoading,setIsLoading]=useState(false)
    const getUser = () => {
        setIsLoading(true)
        axios.get(`${process.env.REACT_APP_SERVER}/get-user-admin?limit=10&page=${page}`)
            .then((res) => {
                setIsLoading(false)
                setUser(res.data.users)
                setCount(res.data.totalCount)
            })
            .catch((eror)=>{
                setIsLoading(false)
            })
    }
    useEffect(() => {
        getUser()
    }, [page])
    return (
        <>
            <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
                <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
                <Drawer
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    returnFocusOnClose={false}
                    onOverlayClick={onClose}
                    size="full">
                    <DrawerContent>
                        <SidebarContent onClose={onClose} />
                    </DrawerContent>
                </Drawer>
                {/* mobilenav */}
                <MobileNav onOpen={onOpen} />
                <Box ml={{ base: 0, md: 60 }} p="4">
                    {/* Content */}
                    <Box w={["95%", "85%", "80%", "90%",]} m="auto">
                    <TableContainer bg="white" >
                        {
                        !isLoading?
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    <Th>Sr.No.</Th>
                                    <Th>User Id</Th>
                                    <Th isNumeric>User Name</Th>
                                    <Th isNumeric>Is Active</Th>
                                    <Th>Mobile Number</Th>
                                    <Th>Wallete Amount</Th>
                                    <Th>Date</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    user.map((el, i) => (
                                        <Tr>
                                            <Td>{i + 1}</Td>
                                            <Td>{el.id}</Td>
                                            <Td isNumeric>{el.first_name} {el.last_name}</Td>
                                            <Td>{el.is_active? "Active":"Disactivated"}</Td>
                                            <Td>{el.mobile}</Td>
                                            <Td>{el.walletAmount}</Td>
                                            <Td isNumeric>{new Date(el.createdAt).toLocaleDateString('en-GB')}</Td>
                                            {/* <Td><PayModal id={el.id} referance={el.referance_id} amount={el.amount} userId={el.userId}/></Td> */}
                                        </Tr>
                                    ))
                                }
                            </Tbody>
                        </Table>:
                        <TableSkalaton/>
                        }
                    </TableContainer>
                    <Box display="flex" justifyContent="end" mt="10px" gap="10px">
                    <Button colorScheme="whatsapp" isDisabled={page===1} onClick={()=>setPage(page-1)}>Prev</Button>
                    <Button colorScheme="whatsapp" isDisabled={page===Math.ceil(count/10)} onClick={()=>setPage(page+1)}>Next</Button>
                    </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
export default Users