import React, { useEffect, useState } from "react";
import {
    Box,
    useColorModeValue,
    Text,
    Drawer,
    DrawerContent,
    useDisclosure,
} from '@chakra-ui/react';
import { MobileNav, SidebarContent } from "../components/AdminSidebar"
import axios from 'axios'
import AddModal from "../components/Faq/AddModal";
const Faq = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [faq, setFaq] = useState([])

    const getData = () => {
        axios(`${process.env.REACT_APP_SERVER}/get-faq`)
            .then((res) => setFaq(res.data))
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
                <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
                <Drawer
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    returnFocusOnClose={false}
                    onOverlayClick={onClose}
                    size="full">
                    <DrawerContent>
                        <SidebarContent onClose={onClose} />
                    </DrawerContent>
                </Drawer>
                {/* mobilenav */}
                <MobileNav onOpen={onOpen} />
                <Box ml={{ base: 0, md: 60 }} p="4">
                    {/* Content */}
                    <Box w="100%" display="flex" alignItems="end" justifyContent="end">
                        <AddModal />
                    </Box>
                    {
                        faq.map((el) => (
                            <Box bg="white" p="2" textAlign="start" mt="3px">
                                <Text fontWeight="bold">Q. {el.question}</Text>
                                <Text>{el.answer}</Text>
                            </Box>
                        ))
                    }
                </Box>
            </Box>
        </>
    )
}
export default Faq