import {
    Box,
    Button,
    FormLabel,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast,
  } from "@chakra-ui/react";
  import axios from "axios";
  import { useState } from "react";
  import { IoMdAddCircleOutline } from "react-icons/io";
  
  const init = {
    name: "",
    price: "",
    videos: [],
  };
  
  const AddPackages = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [formData, setFormData] = useState(init);
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
  
    const handleFormData = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleVideoChange = (index, key, value) => {
      const updatedVideos = [...formData.videos];
      updatedVideos[index] = { ...updatedVideos[index], [key]: value };
      setFormData({ ...formData, videos: updatedVideos });
    };
  
    const addVideoField = () => {
      setFormData({
        ...formData,
        videos: [...formData.videos, { title: "", vId: "" }],
      });
    };
  
    const removeVideoField = (index) => {
      const updatedVideos = [...formData.videos];
      updatedVideos.splice(index, 1);
      setFormData({ ...formData, videos: updatedVideos });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
  
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/add-packages`,
        formData
      );
  
      if (res.status === 202) {
        toast({
          title: "Add is Already Scheduled for this date",
          description: "Please Change the date",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else if (res.status === 200) {
        setIsLoading(false);
  
        toast({
          title: "Add Scheduled",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    };
    return (
      <>
        <Button
          bg="teal.500"
          color="white"
          onClick={onOpen}
          _hover={{ bg: "teal.600" }}
        >
          <Icon as={IoMdAddCircleOutline} boxSize={5} mr={3} />
          Add Package
        </Button>
  
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New Package</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <form action="">
                <FormLabel>Package Name</FormLabel>
                <Input
                  name="name"
                  type="text"
                  onChange={(e) => handleFormData(e)}
                />
                <FormLabel>Package Price</FormLabel>
                <Input
                  name="price"
                  type="number"
                  onChange={(e) => handleFormData(e)}
                />
                <Box display="flex" flexDirection="column" mt="5">
                  {formData.videos.map((video, index) => (
                    <Box key={index} mb="3">
                      <FormLabel>Title</FormLabel>
                      <Input
                        type="text"
                        value={video.title}
                        onChange={(e) =>
                          handleVideoChange(index, "title", e.target.value)
                        }
                      />
                      <FormLabel>Video Id</FormLabel>
                      <Input
                        type="text"
                        value={video.vId}
                        onChange={(e) =>
                          handleVideoChange(index, "vId", e.target.value)
                        }
                      />
                      {index > 0 && (
                        <Button
                          variant="link"
                          color="red.500"
                          size="sm"
                          onClick={() => removeVideoField(index)}
                        >
                          Remove Video
                        </Button>
                      )}
                    </Box>
                  ))}
                  <Button
                    variant="link"
                    color="teal.500"
                    size="sm"
                    onClick={addVideoField}
                  >
                    Add More Videos
                  </Button>
                </Box>
              </form>
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleSubmit} isLoading={isLoading} loadingText={"Savings..."}>
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };
  
  export default AddPackages;
  