import React from "react";
import {
    Box,
    useColorModeValue,
    Drawer,
    DrawerContent,
    useDisclosure,
  } from '@chakra-ui/react';
import { MobileNav, SidebarContent } from "../../components/Dashboard/Sidebar";


const PrivacyPolicy = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
  
    return (
        <>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4" textAlign="start">
          {/* Content */}
          <h2>Privacy Policy for Paarsh Infotech Pvt Ltd</h2>
           <p> Paarsh Infotech Pvt Ltd (“Company”, “we”, “our”, or “us”) is committed to protecting the privacy and security of our users’ personal information. This privacy policy describes how we collect, use, and share information collected from our website users.</p>



            <h4>Consent</h4>
            <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>



            <h4> Information We Collect</h4>
            <p> We may collect personal information from our website users, including but not limited to, name, email address, phone number, mailing address, and other contact information. We may also collect non-personal information such as IP addresses, browser type, and device information.</p>



            <h4> How We Collect Information</h4>
            <p> We may collect information in several ways, including but not limited to, when users visit our website, register on our website, subscribe to our newsletter, fill out a form, or interact with our website in any other way.</p>



            <h4> How We Use Collected Information</h4>
            <p>We may use the collected information for various purposes, including but not limited to, improving our website, communicating with users, processing transactions, and sending periodic emails.</p>



            <h4> How We Keep Information Safe</h4>
            <p>We take reasonable steps to protect user information from unauthorized access, alteration, disclosure, or destruction. We use encryption, firewalls, and other security measures to protect user information.</p>



            <h4> Information Sharing with Third Parties</h4>
            <p>We do not sell, trade, or rent user information to third parties. However, we may share information with trusted third-party service providers who help us operate our website, conduct business, or provide services to our users. We may also disclose information if required by law or to protect our rights or property.</p>



            <h4>Log Files</h4>
            <p>trudigipreneur.com follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.</p>



            <h4>Cookies and Web Beacons</h4>
            <p>Like any other website, tempse uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.</p>



            <h4>Google DoubleClick DART Cookie</h4>
            <p>Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – https://policies.google.com/technologies/ads</p>



            <h4>Advertising Partners Privacy Policies</h4>
            <p>You may consult this list to find the Privacy Policy for each of the advertising partners of trudigipreneur.com.</p>

            <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on paarshinfotech.com, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

            <p>Note that paarshinfotech.com has no access to or control over these cookies that are used by third-party advertisers.</p>



            <h4>Third Party Privacy Policies</h4>
            <p>trudigipreneur.com‘s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>

            <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.</p>



            <p>CCPA Privacy Rights (Do Not Sell My Personal Information)
            Under the CCPA, among other rights, California consumers have the right to:

            Request that a business that collects a consumer’s personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.

            Request that a business delete any personal data about the consumer that a business has collected.

            Request that a business that sells a consumer’s personal data, not sell the consumer’s personal data.

            If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
            


            GDPR Data Protection Rights
            We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:

            The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.

            The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.

            The right to erasure – You have the right to request that we erase your personal data, under certain conditions.

            The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.

            The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.

            The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.

            If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
            </p>


            <h4>Children’s Information</h4>
            <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.

            paarshinfotech.com does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
            </p>


            <h4>Contact Us</h4>
            <p>If you have any questions or concerns regarding this privacy policy or the use of your personal information, please contact us at support@paarshinfotech.com.

            Changes to This Policy
            We may update this privacy policy from time to time. We encourage users to frequently check this page for any changes. Your continued use of our website after any changes to this privacy policy will constitute your acceptance of such changes.
            </p>
        </Box>
      </Box>
      </>
    );
  };
  
export default PrivacyPolicy;