function TopCarousal() {
  return (
    <>
      <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="10000">
            <img src="https://t3.ftcdn.net/jpg/04/76/05/12/360_F_476051263_QYGqAg9ZY3Bhtd0Ti28WawmdCuvBAY1K.jpg" className="d-block w-100 carousel-img" alt="..." />
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <img src="assets/graphics_pic.png" className="d-block w-100 carousel-img" alt="..." />
          </div>
          <div className="carousel-item">
            <img src="assets/home_pic.png" className="d-block w-100 carousel-img" alt="..." />
          </div>
        </div>
        {/* Single static carousel-caption for all slides */}
        <div className="carousel-caption d-md-none"> {/* Visible on mobile devices */}
          <h5>Your Static Slide Label</h5>
          <p>Some representative placeholder content for all slides.</p>
        </div>
        <div className="carousel-caption d-none d-md-block"> {/* Visible on medium and larger devices */}
          <h5>Your Static Slide Label</h5>
          <p>Some representative placeholder content for all slides.</p>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
}

export default TopCarousal;
