import { Box, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { Navigate } from "react-router-dom";

const AdminPrivateRoute = ({ children }) => {
    const toast = useToast()
    let token = localStorage.getItem('adminToken')
  
    if (!token) {
      toast({
        title: 'Login required',
        description: 'You are not logged in',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      })
      return <Navigate to='/admin/login' />
    }
    return children
  }

export default AdminPrivateRoute