/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"
import { Box, Button, FormLabel, Image, useToast } from "@chakra-ui/react";
function Login() {
    const toast=useToast()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoding] = useState(false)
    const navigate = useNavigate()
    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoding(true)
        await axios.post(`${process.env.REACT_APP_SERVER}/login-user`, {
            email, password
        })
            .then((response) => {
                setIsLoding(false)
                if(response.status===200){
                    localStorage.setItem("userToken", response.data.token)
                    localStorage.setItem("userDetails", JSON.stringify(response.data.userInDb))
                    navigate('/mypackages')
                    toast({
                        title: 'Login Success.',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                      })
                }else if(response.status===202){
                    toast({
                        title: 'User Not Found.',
                        description: "Please check Your Email.",
                        status: 'warning',
                        duration: 3000,
                        isClosable: true,
                      })
                }else if(response.status===203){
                    toast({
                        title: 'Wrong Password.',
                        description: "Please check Your Password.",
                        status: 'warning',
                        duration: 3000,
                        isClosable: true,
                      })
                }
            })
            .catch((response) => {
                setIsLoding(false)
            })
    }
    const bgImageStyle = {
        backgroundImage: "url('https://mdbootstrap.com/img/new/textures/full/171.jpg')",
        height: '300px',
    };

    const cardStyle = {
        marginTop: '-100px',
        background: 'hsla(0, 0%, 100%, 0.8)',
        backdropFilter: 'blur(30px)',
    };
    return (
        <>
            <section class="text-center">
                <Box p={5} bg="#d81b53" class="p-5 " h="300px">
                <Image src="logo1.png" w={["80%", "50%", "40%", "40%"]} m="auto"/>
                </Box>
                <div class="card mx-4 mx-md-5 shadow-5-strong" style={cardStyle}>
                    <div class="card-body py-5 px-md-5">

                        <div class="row d-flex justify-content-center">
                            <div class="col-lg-6">
                                <h2 class="fw-bold mb-5">Login Your Account</h2>
                                <form>
                                    <div class="form-outline mb-4">
                                        <FormLabel>Email Address</FormLabel>
                                        <input type="email" id="form3Example3" class="form-control" onChange={(e) => setEmail(e.target.value)} />

                                    </div>

                                    <div class="form-outline mb-4">
                                        <FormLabel>Email Address</FormLabel>
                                        <input type="password" id="form3Example4" class="form-control" onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    

                                    {/* <button type="submit" class="btn btn-primary btn-block mb-4" onClick={handleLogin} disabled={!isLoading} >
                                        {!isLoading? "Logging in..." : "Login"}
                                    </button> */}
                                    
                                    <Button w="80%" bg="#d81b45" type="submit" color="white" onClick={handleLogin} isLoading={isLoading} loadingText="Signing">Login</Button>
                                    

                                    <div class="form-check d-flex justify-content-center mt-4">
                                        Not a member ? <Link to="/register">Register</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Login