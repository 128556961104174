import { Box, Image, Link, Text } from '@chakra-ui/react';
const Footer = () => {
    return (
        <>
        <Box as="footer" textAlign="center" p={2} pb={1}mt={8} borderTop="1px" borderColor="gray.200" bg="#0E1E2C" display={["", "flex", "flex", "flex"]} justifyContent="space-between">
            <Box display="flex" justifyContent="space-between" w={["100%","50%","50%","50%"]} alignItems="center">  
                <Box display={["","flex","flex","flex"]} gap="10px" textAlign="start">
                    <Text fontSize="sm" mt="10px">
                        <Link href="/privacy-policy-app" isExternal color="gray.400">Privacy Policy</Link>
                    </Text>
                    <Text fontSize="sm" mt="10px">
                        <Link href="/terms-condition-app" isExternal color="gray.400">Terms & Conditions</Link>
                    </Text>
                </Box>
                <Box display="flex" gap={["7px","8px","10px","10px"]} alignItems="center">
                    <Image src="facebook.png" w="30px" h="30px" />
                    <Image src="instagram.png" w="30px" h="30px" />
                    <Image src="linkedin.png" w="30px" h="30px" />
                    <Image src="twitter.png" w="30px" h="30px" />
                </Box>
            </Box>
            <Text fontSize="sm" color='gray' mt={["7px","","",""]}>
                © 2023-24 Paarsh Infotech Pvt Ltd. All Rights Reserved.
            </Text>
        </Box>
        </>
    )
}
export default Footer