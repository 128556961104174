/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { Link as ScrollLink, Element } from 'react-scroll';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [scrollY, setScrollY] = useState(0);
  const [activeSection, setActiveSection] = useState('');

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sections = ['home', 'about', 'package'];

  const handleSetActive = (to) => {
    setActiveSection(to);
  };

  return (
    <Flex
      justify="space-between"
      align="center"
      p={[4, 1, 1, 1]}
      bg={scrollY > 300 ? '#d81b53' : 'transparent'}
      color="white"
      position="sticky"
      top="0"
      zIndex="1"
      justifyContent="space-between"
      transition="background-color 0.3s ease-in-out"
    >
      <Box w={['40%', '60%', '60%', '60%']}>
        <Image src="logo1.png" w={["70%", "30%", "30%", "30%"]} />
      </Box>

      <Box w={['60%', '40%', '40%', '40%']} justifyContent="space-between" display="flex">
        {sections.map((section) => (
          <Element name={section} key={section}>
            <ScrollLink
              to={section}
              smooth={true}
              duration={500}
              spy={true}
              activeClass="active"
              onSetActive={() => handleSetActive(section)}
            >
              <Button
                variant="link"
                mr={2}
                color={activeSection === section ? '#ffcc00' : 'white'} // Change text color based on active section
                fontWeight="bold"
                fontSize={['15px', '20px', '20px', '20px']}
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </Button>
            </ScrollLink>
          </Element>
        ))}
        <Link to="/login"><Button
          variant="link"
          mr={2}
          fontWeight="bold"
          color="white"
          fontSize={['15px', '20px', '20px', '20px']}
        >
          Login
        </Button></Link>
      </Box>
    </Flex>
  );
};

export default Navbar;
