import { Button, FormLabel, Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react"
import axios from "axios"
import { useState } from "react"
import { IoMdAddCircleOutline } from 'react-icons/io'

const init = {
    title: "",
    vId: "",
    add_date: ""
}
const AddsModel = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [formData, setFormData] = useState(init)
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(false)
    const handelFormData = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {

        e.preventDefault()
        setIsLoading(true)
        const res= await axios.post(`${process.env.REACT_APP_SERVER}/add-adds`, formData)
        if(res.status==202){
            setIsLoading(false)
            onClose()
            toast({
                title: 'Add is Already Scheduled for this date',
                description: 'Please Change the date',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
              })
        }else if(res.status==200){
            setIsLoading(false)
            onClose()
            toast({
                title: 'Add Scheduled',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top',
              })
        }
        window.location.reload()
    }
    return (
        <>
            <Button bg="teal.500" color="white" onClick={onOpen} _hover={{ bg: "teal.600" }}>
                <Icon as={IoMdAddCircleOutline} boxSize={5} mr={3} />
                Schedule Adds</Button>

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Schedule New Advertisement</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form action="" id="scheduleAd"  onSubmit={handleSubmit}>
                            <FormLabel>Title</FormLabel>
                            <Input name="title" type="text" onChange={(e) => handelFormData(e)} required/>
                            <FormLabel>Video Id</FormLabel>
                            <Input name="vId" type="text" onChange={(e) => handelFormData(e)} required/>
                            <FormLabel>Schedule Date</FormLabel>
                            <Input name="add_date" type="date" onChange={(e) => handelFormData(e)} required/>
                        </form>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' type="submit" mr={3} isLoading={isLoading} loadingText={"Saveing..."} form="scheduleAd">
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AddsModel 