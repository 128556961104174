import { Button, FormLabel, Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, useDisclosure, useToast } from "@chakra-ui/react"
import axios from "axios"
import { useEffect, useState } from "react"
import { IoMdAddCircleOutline } from 'react-icons/io'

const AddCategoriesModel = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(false)
    const [title, setTitle]=useState("")

    const handleSubmitCategory = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const res= await axios.post(`${process.env.REACT_APP_SERVER}/add-faq-categories`, {title})
        if(res.status==200){
            setIsLoading(false)
            onClose()
            toast({
                title: ' Category Added ',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top',
              })
        }
        
    }
    return (
        <>
            
                <Icon onClick={onOpen} as={IoMdAddCircleOutline} boxSize={5} mr={3} />
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Category</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        
                            <FormLabel>Category Title</FormLabel>
                            <Input type="text" onChange={(e)=>setTitle(e.target.value)} required/>
                        
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} isLoading={isLoading} loadingText={"Saveing..."} onClick={handleSubmitCategory}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AddCategoriesModel