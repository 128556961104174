import React, { useEffect, useState } from "react";
import {
    Box,
    useColorModeValue,
    Text,
    Drawer,
    DrawerContent,
    useDisclosure,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
} from '@chakra-ui/react';
import { MobileNav, SidebarContent } from "../components/AdminSidebar"
import axios from "axios";
import PendingAdds from "../components/Adds/PendingAdds";
import AddsModel from "../components/Adds/AddsModel";

const Adds = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [data, setData] = useState([])
    const [exdata, setExData] = useState([])

    const getAddsData = () => {
        axios.get(`${process.env.REACT_APP_SERVER}/get-adds?is_expired=false`)
            .then((res) => setData(res.data))
    }

    const getExAddsData = () => {
        axios.get(`${process.env.REACT_APP_SERVER}/get-adds?is_expired=true`)
            .then((res) => setExData(res.data))
    }
    // console.log(data);
    useEffect(() => {
        getAddsData()
        getExAddsData()
    }, [])
    return (
        <>
            <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
                <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
                <Drawer
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    returnFocusOnClose={false}
                    onOverlayClick={onClose}
                    size="full">
                    <DrawerContent>
                        <SidebarContent onClose={onClose} />
                    </DrawerContent>
                </Drawer>
                {/* mobilenav */}
                <MobileNav onOpen={onOpen} />
                <Box ml={{ base: 0, md: 60 }} p="4">
                    {/* Content */}
                    <Box w="100%" display="flex" alignItems="end" justifyContent="end">
                        <AddsModel/>
                        
                    </Box>
                    
                    <Box className="table-responsive" mt="10px" boxShadow="md">
                    <Text fontWeight="bold" textAlign="start" fontSize="20px">Scheduled Advertisement</Text>
                    <Table className="table table-striped">
                        <Thead>
                            <Tr>
                                <Th >Sr. No.</Th>
                                <Th>Title</Th>
                                <Th>Video Id</Th>
                                <Th>Date</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                data.map((item, i) => (
                                    <PendingAdds key={item.id} props={item} i={i}/>
                                ))
                            }

                        </Tbody>
                    </Table>
                    </Box>


                    <Box className="table-responsive" mt="30px" boxShadow="md">
                    <Text fontWeight="bold" textAlign="start" fontSize="20px">Expired Advertisement</Text>
                    <Table className="table table-striped">
                        <Thead>
                            <Tr>
                                <Th >Sr. No.</Th>
                                <Th>Title</Th>
                                <Th>Video Id</Th>
                                <Th>Date</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                exdata.map((item, i) => (
                                    <PendingAdds key={item.id} props={item} i={i}/>
                                ))
                            }

                        </Tbody>
                    </Table>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
export default Adds