import React from 'react';
import { Box, Text, Button, Link } from '@chakra-ui/react';
import { SlideFade } from '@chakra-ui/react';

const NotFound = () => {
  return (
    <SlideFade in={true} offsetY="20px">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        h="100vh"
        bgGradient="linear(to-l, teal.200, teal.500)"
        borderRadius="md"
        boxShadow="lg"
        p="8"
        color="white"
      >
        <Text fontSize="4xl" fontWeight="bold" mb={2}>
          404
        </Text>
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          Page Not Found
        </Text>
        <Text fontSize="lg" mb={6}>
          The page you are looking for does not exist.
        </Text>
        <Link to="/">
          <Button colorScheme="teal" size="lg">
            Go to Home
          </Button>
        </Link>
      </Box>
    </SlideFade>
  );
};

export default NotFound;
