import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const init = {
    first_name: "",
    last_name: "",
    email: "",
    mobile: 0,
    gender: "",
    state: "",
    city: "",
    agent_code: 0,
    package: 0,
    password: ""
}
function Register() {
    const [formData, setFormData] = useState(init)
    const [isLoading, setIsLoading] = useState(false)
    const [packages, setPackages] = useState([])
    const navigate = useNavigate()
    function handleFormData(e) {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const getPackage = async () => {
        axios.get(`${process.env.REACT_APP_SERVER}/get-packages`)
            .then((res) => setPackages(res.data))
    }

    useState(() => {
        getPackage()
    }, [])
    const handleRegister = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        await axios.post(`${process.env.REACT_APP_SERVER}/register-user`, formData)
            .then((response) => {
                setIsLoading(false)
                navigate('/login')
            })
            .catch((response) => {
                setIsLoading(false)
            })
    }

    const bgImageStyle = {
        backgroundImage: "url('https://mdbootstrap.com/img/new/textures/full/171.jpg')",
        height: '300px',
    };

    const cardStyle = {
        marginTop: '-200px',
        background: 'hsla(0, 0%, 100%, 0.8)',
        backdropFilter: 'blur(30px)',
    };
    return (
        <>

            <section class="text-center">

                <div class="p-3 bg-image" style={bgImageStyle}></div>

                <div class="d-flex justify-content-center " >
                    <div class="card mx-4 mx-md-5 w-md-50 w-sm-75 shadow-5-strong mb-5" style={cardStyle}>
                        <div class="card-body py-5 px-md-5">

                            <div class="row d-flex justify-content-center">
                                <div class="col-lg-8">
                                    <h2 class="fw-bold mb-5">Sign up now</h2>
                                    <form>

                                        <div class="row">
                                            <div class="col-6 md-6 mb-4">
                                                <div class="form-outline">
                                                    <input type="text" id="form3Example1" class="form-control" name="first_name" onChange={(e) => handleFormData(e)} />
                                                    <label class="form-label" for="form3Example1">First name</label>
                                                </div>
                                            </div>
                                            <div class="col-6 md-6 mb-4">
                                                <div class="form-outline">
                                                    <input type="text" id="form3Example2" class="form-control" name="last_name" onChange={(e) => handleFormData(e)} />
                                                    <label class="form-label" for="form3Example2">Last name</label>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="form-outline mb-4">
                                            <input type="email" id="form3Example3" class="form-control" name="email" onChange={(e) => handleFormData(e)} />
                                            <label class="form-label" for="form3Example3">Email address</label>
                                        </div>


                                        <div class="form-outline mb-4">
                                            <input type="password" id="form3Example4" class="form-control" name="password" onChange={(e) => handleFormData(e)} />
                                            <label class="form-label" for="form3Example4">Password</label>
                                        </div>

                                        <div class="form-outline mb-4">
                                            <input type="number" id="form3Example4" class="form-control" name="mobile" onChange={(e) => handleFormData(e)} />
                                            <label class="form-label" for="form3Example4">Mobile Number</label>
                                        </div>

                                        {/* <div class="form-outline mb-4">
                                            <input type="text" id="form3Example4" class="form-control" name="state" onChange={(e)=>handleFormData(e)} />
                                            <label class="form-label" for="form3Example4">State</label>
                                        </div>

                                        <div class="form-outline mb-4">
                                            <input type="text" id="form3Example4" class="form-control" 
                                            name="city" onChange={(e)=>handleFormData(e)}/>
                                            <label class="form-label" for="form3Example4">City</label>
                                        </div> */}

                                        <div class="row">
                                            <div class="col-6 md-6 mb-4 sm-3">
                                                <div class="form-outline ">
                                                    <input type="text" id="form3Example4" class="form-control" name="state" onChange={(e) => handleFormData(e)} />
                                                    <label class="form-label" for="form3Example4">State</label>
                                                </div>
                                            </div>

                                            <div class="col-6 md-6 mb-4 sm-3">
                                                <div class="form-outline ">
                                                    <input type="text" id="form3Example4" class="form-control" name="city" onChange={(e) => handleFormData(e)} />
                                                    <label class="form-label" for="form3Example4">City</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-6 md-6 mb-4 sm-3">
                                                <div class="form-outline ">
                                                    <label class="form-label" for="form3Example4">Gender</label>
                                                    <select id="form3Example4" class="form-control" name="gender" onChange={(e) => handleFormData(e)}>
                                                        <option value="">Select Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>

                                                </div>
                                            </div>

                                            <div class="col-6 md-6 mb-4 sm-3">
                                                <div class="form-outline ">
                                                    <label class="form-label" for="form3Example4">Package</label>
                                                    <select id="form3Example4" class="form-control" name="package" onChange={(e) => handleFormData(e)}>
                                                        <option value="">Select Package</option>
                                                        {
                                                            packages && packages.map((item) => (
                                                                <option key={item.id} value={item.id}>{item.name}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-outline mb-4 justify-content-center">
                                            <input type="number" id="form3Example4" class="form-control m-auto w-50" name="agent_code" onChange={(e) => handleFormData(e)} />
                                            <label class="form-label" for="form3Example4">Agent Code</label>
                                        </div>

                                        <div class="form-check d-flex justify-content-center mb-4">
                                            <p>Akready have account ? <Link to="/login">Login</Link> </p>
                                        </div>


                                        <button type="submit" class="btn btn-primary btn-block mb-4" disabled={isLoading} onClick={handleRegister}>
                                            {isLoading ? "Sigging up..." : "Sign up"}
                                        </button>


                                        <div class="text-center">
                                            <p>or sign up with:</p>
                                            <button type="button" class="btn btn-link btn-floating mx-1">
                                                <i class="fab fa-facebook-f"></i>
                                            </button>

                                            <button type="button" class="btn btn-link btn-floating mx-1">
                                                <i class="fab fa-google"></i>
                                            </button>

                                            <button type="button" class="btn btn-link btn-floating mx-1">
                                                <i class="fab fa-twitter"></i>
                                            </button>

                                            <button type="button" class="btn btn-link btn-floating mx-1">
                                                <i class="fab fa-github"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Register