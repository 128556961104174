/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import '../styles.css';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { Box, Button, HStack, Heading, List, ListIcon, ListItem, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


export default function Slider() {
    const [packages, setPackages] = useState([])
    const [isLoading, setLoading2] =useState(false)
    const navigate=useNavigate()
    const getPackage = () => {
        axios.get(`${process.env.REACT_APP_SERVER}/get-all-packages`)
            .then((res) => setPackages(res.data))
    }

    useEffect(() => {
        getPackage();
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');
        const medium = searchParams.get('medium');
        if (token) {
            axios.post(`${process.env.REACT_APP_SERVER}/get-user-details`,{token})
            .then((res)=>localStorage.setItem("userDetails",JSON.stringify(res.data)))
            localStorage.setItem('userToken', token);
        }
        if (medium && medium === 'app') {
            sessionStorage.setItem('medium', 'app');
        }
    }, [])

    //  const data ={
    //      name: 'Waleed',
    //      amount: 1,
    //      number: '7498608775',
    //      MUID: "MUID" + Date.now(),
    //      transactionId: 'T' + Date.now(),
    //  }

    //    const handlePayment = (e)=>{
    //     //  newPayment()
    //      setLoading2(true);
    //      axios.post(`${process.env.REACT_APP_SERVER}/api/payment`, {...data}).then(res => {  
    //       console.log("res",res);
    //       window.location.href = res.data;
    //      setTimeout(() => {
    //          setLoading2(false);
    //      }, 1500);
    //      })
    //      .then((res)=>console.log(res))
    //      .catch(error => {
    //          setLoading2(false)
    //          console.error(error);
    //      });   
    //  }
    const handleSubmit = async (item) => {
        const token=localStorage.setItem('userToken',item.id)
        if(!token){
            navigate('/login')
        }
        localStorage.setItem('packageId',item.id)
        const userDetails = JSON.parse(localStorage.getItem('userDetails'))
         const data ={
         name: userDetails?.first_name,
         amount: item.price,
         number: userDetails?.mobile,
         MUID: "MUID" + Date.now(),
         transactionId: 'T' + Date.now() + userDetails?.id,
         userId:userDetails.id,
         packageId:item.id
     }
        setLoading2(true);
        await axios.post(`${process.env.REACT_APP_SERVER}/payment`, { ...data }).then(res => {
            window.location.href = res.data;
            setTimeout(() => {
                setLoading2(false);
            }, 1500);
        })
            .then()
            .catch(error => {
                setLoading2(false)
                console.error(error);
            });
    }
    return (
        <>
            <Box >
                <VStack spacing={2} textAlign="center">
                    <Heading as="h1" fontSize="4xl">
                        Plans that fit your need
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                        Start with 14-day free trial. No credit card needed. Cancel at anytime.
                    </Text>
                </VStack>
            </Box>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
            >
                {
                    packages && packages.map((item) => (

                        <SwiperSlide key={item.id}>
                            <Box
                                shadow="base"
                                borderWidth="1px"
                                alignSelf={{ base: 'center', lg: 'flex-start' }}
                                // borderColor={useColorModeValue('gray.200', 'gray.500')}
                                borderRadius={'xl'}>

                                <Box py={4} px={12}>
                                    <Text fontWeight="500" fontSize="2xl">
                                        {item.name}
                                    </Text>
                                    <HStack justifyContent="center">
                                        <Text fontSize="3xl" fontWeight="600">
                                            ₹
                                        </Text>
                                        <Text fontSize="5xl" fontWeight="900">
                                            {item.price}
                                        </Text>
                                        {/* <Text fontSize="3xl" color="gray.500">
                                    /month
                                </Text> */}
                                    </HStack>
                                </Box>
                                <VStack
                                    // bg={useColorModeValue('gray.50', 'gray.700')}
                                    py={4}
                                    borderBottomRadius={'xl'}>
                                    <List spacing={3} textAlign="start" px={12}>
                                      {
                                        item.features.map((el)=>(
                                          <ListItem>
                                            <ListIcon as={FaCheckCircle} color="green.500" />
                                            {el} 
                                        </ListItem>
                                        ))
                                      }
                                    </List>
                                    <Box w="80%" pt={7}>
                                        <Button w="full" colorScheme="red" variant="outline" isLoading={isLoading} onClick={() => handleSubmit(item)}>
                                            Buy Package
                                        </Button>
                                    </Box>
                                </VStack>
                            </Box>
                        </SwiperSlide>

                    ))
                }
            </Swiper>
        </>
    );
}
